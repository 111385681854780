<template>
    <div class="overlay" :class="{'showNotMemberCustomerPannel':$store.state.isShowNotMemberCustomerePanel && !$store.state.isLogin}">
        <div class="overlay-warp">
            <div class="overlay-open">
                <div class="register-warp">
                    <div class="login-head">
                            <img src="../assets/images/logo/balam/logo01.png" style="height: 50px"/>

                    </div>
                    <div style="padding: 20px">
                        <div>
                            <textarea v-model="content" style="border: 1px solid #143771;background-color: #c6c6c6;border-radius:10px;border:1px solid #ffa604;outline: transparent" placeholder="비회원문의"></textarea>
                        </div>
<!--                        <div style="background-color: #fd3838;border-radius: 10px;padding: 20px;font-size: 14px;font-weight: bold;;text-align: center">-->
<!--                            현재 비회원 문의 및 광고스폰서<br/>-->
<!--                            <font style="color: gold">추천인이 없는 가입은 받지 않습니다</font><br/>-->
<!--                            가입이용 중이신 지인 혹은 추천인을 통해<br/>-->
<!--                            연락 부탁 드립니다-->
<!--                        </div>-->

                        <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
                            <button style="width: 130px;background-color: #db4540;height: 40px;margin-right: 5px;border: 1px solid #ffffff;border-radius: 5px" @click="saveNotMemberQuestion">작성하기</button>
<!--                            <button style="width: 130px;background-color: var(&#45;&#45;loginBtnBg);height: 40px;margin-right: 5px">업데이트중입니다</button>-->
                            <button style="width: 80px;background-color: #ff0000;height: 40px;border-radius: 5px" @click="closePanel">닫기</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../common/mixin";
    import {register, saveNotMemberQuestion} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";

    export default {
        name: "NotMemeberCustomerComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                content: '',
            }
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.content=''
            },
            saveNotMemberQuestion() { //회원가입시 아이디 체크
                if (this.content == '') {
                    this.$swal({
                        title:'내용을 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                saveNotMemberQuestion({'content': this.content}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$store.state.isShowLoginPanel = false;
                    this.$store.state.isShowRegisterPanel = false;
                    this.$store.state.isShowNotMemberCustomerePanel = false;
                    this.content=''
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '문의완료',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

        },
        created() {
        },
        computed: {

        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .register-warp {
            width: 370px !important;
            height: 350px !important;
            background-color: #fff9ff;
        }

        .register-warp .register-cont .register-d .register-left {
            width: 100% !important;
        }

        .register-warp .register-cont .register-d .register-right {
            display: none !important;
        }
    }

    .showNotMemberCustomerPannel {
        transform: translateY(0%);
    }

    .register-warp {
        width: 410px;
        height: 350px;
        background-color: var(--loginWarpBg);
        color: #fff9ff;
        border:  1px solid #1f1f1f;
    }

    .register-warp .login-head {
        background-color: #2a2a2a;
        color: #fff;
        height:100px;
        line-height: 100px;
        padding: 0 5px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .register-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 40px;
        padding: 2px;
        cursor: pointer;
    }

    .login-panel-close img {
        height: 30px;
    }

    .register-warp .register-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 5001;
        margin-top: -40px;
    }

    .register-warp .register-cont .register-d {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 40px;
    }

    .register-warp .register-cont .register-d .register-left {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
        position: relative;
    }

    .error-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #ff0000;
    }

    .register-warp .register-cont .register-d .register-left .register-logo {
        text-align: center;
        margin-top: 14px;
    }

    .register-warp .register-cont .register-d .register-left .register-logo img {
        max-width: 230px;
    }

    .register-warp .register-cont .register-d .register-left .register-from {
        width: 100%;
        padding: 0 16px 16px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        margin-top: 20px;
        padding: 0 2px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field span {
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        font-size: 13px;
        color: #adadad;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field input {
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #3c3b3b;
        border-radius: 0;
        color: #fff9ff;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: left;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button {
        width: 100%;
        padding: 0 16px 16px;
        margin-top: 5px;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button button {
        width: 100% !important;
    }

    .register-warp .register-cont .register-d .register-left .login {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
    }

    .register-warp .register-cont .register-d .register-left .register {
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
        background-color: var(--regBtnBg);
        color: #fff9ff;
    }

    .register-warp .register-cont .register-d .register-left .tel-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #0b1423;
        line-height: 28px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom {
        width: 100%;
        background-color: #2b2f35;
        position: absolute;
        bottom: 0;
        padding: 16px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom .join-text {
        color: #fff9ff;
        font-size: 12px;
    }

    /*login panel right*/
    .register-warp .register-cont .register-d .register-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
    }

</style>