
export default {
    ENABLE: 1,
    DISABLE: 0,
    UNCHECKED: 2,
    CHECKED: 1,
    YES: 1,
    NOT: 0,
    MODE_SAVE: 'save',
    MODE_EDIT: 'edit',

    GameType: {
        ALL: 0,
        SOCCER: 49,
        BASEBALLL: 50,
        BASKETBALL: 51,
        VOLLEYBALL: 52,
        ICEHOCKEY: 53,
        FOOTBALL: 54,//미식축구
        TENNIS: 55,
        ESPORTS: 56,
        OTHER: 58,
        UFC: 59,
    },

    Game: {
        GAME_KIND_1X2: 1,
        GAME_KIND_HANDICAP: 2,
        GAME_KIND_OVERUNDER: 3,
        GAME_KIND_ODD_EVEN: 10,
        GAME_KIND_BOTH_TEAM_GOAL_IN: 11,//양팀모두득점
        GAME_KIND_FINAL_SCORE: 12, //최종스코어
        GAME_KIND_HITS_COUNT: 12, //최종스코어
        GAME_KIND_OTHER: 99,
        GAME_ATTR_FULLTIME: 1,
        GAME_ATTR_SECTIONTIME: 2,

        GAME_DISPLAYPOSITION_1x2: 1,
        GAME_DISPLAYPOSITION_HANDICAPOVERUNDER: 2,
        GAME_DISPLAYPOSITION_SPECIAL: 3,
        GAME_DISPLAYPOSITION_LIVE: 4,
        GAME_DISPLAYPOSITION_SIMULATION_SOCCER: 5,
        GAME_DISPLAYPOSITION_INPLAY: 99,
        GAME_DISPLAYPOSITION_ONLY_BET_GAME: 1,
        /*대기중*/
        GAME_RESULT_WATING: 0,
        /*홈팀승*/
        GAME_RESULT_WIN_HOME: 1,
        /*무*/
        GAME_RESULT_DRAW: 2,
        /*원정승*/
        GAME_RESULT_WIN_AWAY: 3,
        /*적중특례*/
        GAME_RESULT_SPECIAL: 4,
        /*취소*/
        GAME_RESULT_CANCELED: 5,
        GAME_RESULT_INPLAY: 99,

        SCORE_EMPTY: -1,
        GAME_SELECT_HOME: 1,
        GAME_SELECT_DRAW: 2,
        GAME_SELECT_AWAY: 3,
        /*경기체크등록중(배팅불가,유저페이지에 보여안짐)*/
        STATUS_CHECKING: 0,
        /*경기오픈(베팅가능)*/
        STATUS_OPEN: 1,
        /*경기시작(베팅마감)*/
        STATUS_STARTING: 2,
        /*경기종료(베팅마감),결과처리*/
        STATUS_END: 3,
        WAY2: 2,
        WAY3: 3,
        ODDS_STATUS_ENABLE: 1,
        ODDS_STATUS_DISABLE: 2,

        INPLAY_YES: 1,
        INPLAY_NOT: 0,

        GAME_PROTO: 1,
        GAME_LEISURE: 2,

        SPORTS_ID_SOCCER: 1,
        SPORTS_ID_BASEBALL: 2,
        SPORTS_ID_BASKETBALL: 3,
        SPORTS_ID_VOLLEYBALL: 4,
        SPORTS_ID_ICEHOKEY: 5,

        SEARCH_BY_GAMEID: "gameid",
        SEARCH_BY_HOME_TEAMNAME: "hometeamname",
        SEARCH_BY_AWAY_TEAMNAME: "awayteamname",
    },

    LeisureGame: {
        LEISURE_RESULT_WAITING: "waiting",
        LEISURE_RESULT_CACEL: "cancel",
        LEISURE_RESULT_SPECIALCASE: "specialcase",
        LOTTO_BET_CASH: 1000,
        RESULT_ODD: 'odd',
        RESULT_EVEN: 'even',
        RESULT_OVER: 'over',
        RESULT_UNDER: 'under',
        RESULT_LINE_COUNT_3: 'line3',
        RESULT_LINE_COUNT_4: 'line4',
        RESULT_START_LEFT: 'left',
        RESULT_START_RIGHT: 'right',

        WAY_2: 2,
        WAY_3: 3,
        WAY_4: 4,
        WAY_5: 5,
        WAY_6: 6,
        WAY_10: 10,
        WAY_15: 15,

        /**
         * 레이저 종류 (KIND)
         */
        /*파워볼 ok*/
        LEISURE_KIND_POWERBALL: 1,

        /*파워사디리 ok*/
        LEISURE_KIND_POWERSADALI: 2,
        /*스피드키노*/
        LEISURE_KIND_SPEEDKINO: 3,
        /*키노사다리*/
        LEISURE_KIND_KINOSADALI: 4,

        /*네임드다리다리 ok*/
        LEISURE_KIND_NAMED_DALIDALI: 10,
        LEISURE_KIND_NAMED_SADALI: 11,
        LEISURE_KIND_NAMED_SPEEDSADALI: 12,
        LEISURE_KIND_NAMED_SNAIL: 13,

        /*fx1m*/
        LEISURE_KIND_FX_1M: 20,
        LEISURE_KIND_FX_2M: 21,
        LEISURE_KIND_FX_3M: 22,
        LEISURE_KIND_FX_4M: 23,
        LEISURE_KIND_FX_5M: 24,

        LEISURE_KIND_BAKARA_LOTUS_1: 25,
        LEISURE_KIND_BAKARA_LOTUS_2: 26,
        LEISURE_KIND_ODDEVEN_LOTUS: 28,
        LEISURE_KIND_LOTUS_DRAGONTIGER: 30,

        LEISURE_KIND_MGM_BAKARA: 29,
        LEISURE_KIND_MGM_ODDEVEN: 27,

        LEISURE_KIND_EOS_POWERBALL_1M: 32,
        LEISURE_KIND_EOS_POWERBALL_3M: 33,
        LEISURE_KIND_EOS_POWERBALL_5M: 34,
        LEISURE_KIND_HS_POWERBALL_3M: 35,
        LEISURE_KIND_HS_POWERBALL_5M: 36,


        LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK: 40,
        LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK: 41,

        LEISURE_KIND_BET365_BASKETBALL_WARTER: 43,
        LEISURE_KIND_BET365_BASKETBALL_BAKER: 44,
        LEISURE_KIND_BET365_SOCCER_PREMIERSHIP: 45,
        LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE: 46,
        LEISURE_KIND_BET365_SOCCER_EUROCUP: 47,
        LEISURE_KIND_BET365_SOCCER_WORLDCUP: 48,


        LEISURE_KIND_BUSTABIT: 50,

        LEISURE_KIND_BET365_HORCERACE: 42,
        LEISURE_KIND_BET365_HORCERACE_2: 51, //브리타니아 웨이
        LEISURE_KIND_BET365_HORCERACE_3: 52, //페스티발 다운즈
        LEISURE_KIND_BET365_HORCERACE_4: 53, //픽토리아 파크


        LEISURE_KIND_CASINO: 60,
        LEISURE_KIND_SUTDA: 65,
        LEISURE_KIND_NINEBALL: 66,
        //sky 경기
        LEISURE_KIND_SKY_SPEED_BAKARA: 70,
        LEISURE_KIND_SKY_ODDEVEN: 71,
        LEISURE_KIND_SKY_DICE: 72, //주사위


        LEISURE_KIND_KAKAO_SD_1: 73, //카카오1분사다리
        LEISURE_KIND_KAKAO_SD_2: 74, //카카오1분사다리
        LEISURE_KIND_KAKAO_SD_3: 75, //카카오1분사다리

        LEISURE_KIND_STAR_SD_1: 76, //별다리1분사다리
        LEISURE_KIND_STAR_SD_2: 77, //별다리1분사다리
        LEISURE_KIND_STAR_SD_3: 78, //별다리1분사다리


        LEISURE_KIND_TGAME365_CASINO: 90, //Tgame 카지노
        LEISURE_KIND_TGAME365_SLOT: 91, //Tgame 슬롯
        LEISURE_KIND_TGAME365_FISH: 92, //Tgame 슬롯
        LEISURE_KIND_TGAME365_FW: 93, //Tgame 토큰휠
        LEISURE_KIND_TGAME365_HILOW: 94, //Tgame 하이로
        LEISURE_KIND_TGAME365_SNAIL3: 95, //Tgame 달팽이3
        LEISURE_KIND_TGAME365_SNAIL4: 96, //Tgame 달팽이4
        LEISURE_KIND_TGAME365_RUOLLET: 97, //Tgame 룰렛


        //레이저게임 속성: leisure_attribute
        //파워볼 로또
        LEISURE_ATTRIBUTE_LOTTO_POWERBALL: 0,
        //파워볼 파워볼 단일번호선택
        LEISURE_ATTRIBUTE_POWERBALL_NUMBER: 1,
        //파워볼 구간 A,B,C,D
        LEISURE_ATTRIBUTE_POWERBALL_INTERVAL: 2,
        //파워볼 오버 ,언더
        LEISURE_ATTRIBUTE_POWERBALL_OVERUNDER: 3,
        //파워볼 홀,짝
        LEISURE_ATTRIBUTE_POWERBALL_ODD_EVEN: 4,
        //파워볼 홀오버,홀언더,짝오버,짝언더
        LEISURE_ATTRIBUTE_POWERBALL_ODDEVEN_UNDEROVER: 5,
        //파워볼 일반볼 구간 A,B,C,D,E,F
        LEISURE_ATTRIBUTE_POWERBALL_NOMALBALL_INTERVAL: 6,
        //파워볼 일반볼합계 오버,언더
        LEISURE_ATTRIBUTE_POWERBALL_NOMALBALLTOTOAL_OVERUNDER: 7,
        //파워볼 일반볼 홀,짝
        LEISURE_ATTRIBUTE_POWERBALL_NOMALBALL_ODD_EVEN: 8,
        //파워볼 일반볼 대,중,소 Big Middle Small
        LEISURE_ATTRIBUTE_NOMALBALL_BMS: 9,
        //파워볼 일반볼 홀오버,홀언더,짝오버,짝언더
        LEISURE_ATTRIBUTE_POWERBALL_NOMALBAL_ODDEVEN_UNDEROVER: 10,
        //파워볼 일반볼 홀대,홀중,홀소,짝대,짝중,짝소
        LEISURE_ATTRIBUTE_POWERBALL_NOMALBAL_ODDEVEN_BMS: 11,

        //파워사다리 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN: 20,
        LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT: 21,
        LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT: 22,

        //스피드키노 홀짝 ,오버언더
        LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN: 26,
        LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER: 27,

        //키노사다리 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_KINOSADALI_ODDEVEN: 30,
        LEISURE_ATTRIBUTE_KINOSADALI_LEFT_RIGHT: 31,
        LEISURE_ATTRIBUTE_KINOSADALI_LINE_COUNT: 32,

        //네임드 다리다리 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_NAMEDDALIDALI_ODDEVEN: 35,
        LEISURE_ATTRIBUTE_NAMEDDALIDALI_LEFT_RIGHT: 36,
        LEISURE_ATTRIBUTE_NAMEDDALIDALI_LINE_COUNT: 37,

        //네임드 SADALI 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_NAMEDSADALI_ODDEVEN: 40,
        LEISURE_ATTRIBUTE_NAMEDSADALI_LEFT_RIGHT: 41,
        LEISURE_ATTRIBUTE_NAMEDSADALI_LINE_COUNT: 42,

        //네임드 SPEED SADALI 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN: 45,
        LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT: 46,
        LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT: 47,

        //네임드 달팽이 홀짝 , 오버언더, 우승번호
        LEISURE_ATTRIBUTE_NAMEDSNAIL_ODDEVEN: 50,
        LEISURE_ATTRIBUTE_NAMEDSNAIL_OVERUNDER: 51,
        LEISURE_ATTRIBUTE_NAMEDSNAIL_WINNUMBER: 52,

        //Bet365 개경주
        LEISURE_ATTRIBUTE_BET365_DOGRACE_GOLDENHILLPARK_NORMAL: 60,
        LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL: 61,
        //Bet365 가상축구
        LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2: 62,
        LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER: 63,
        LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2: 64,
        LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER: 65,
        LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_1x2: 66,
        LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_OVERUNDER: 67,
        LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_1x2: 68,
        LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_OVERUNDER: 69,

        //Bet365 s농구
        LEISURE_ATTRIBUTE_BET365_BASKETBALL_WARTER_1x2: 70,
        LEISURE_ATTRIBUTE_BET365_BASKETBALL_WARTER_OVERUNDER: 71,
        LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2: 72,
        LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER: 73,
        //가상경마1분
        LEISURE_ATTRIBUTE_BET365_HORCERACE_NORMAL: 74,
        LEISURE_ATTRIBUTE_BET365_HORCERACE_2_NORMAL: 75, //브리타니아 웨이
        LEISURE_ATTRIBUTE_BET365_HORCERACE_3_NORMAL: 76, //페스티벌 다운즈
        LEISURE_ATTRIBUTE_BET365_HORCERACE_4_NORMAL: 77, //빅토리아 파크

        //바카라
        LEISURE_ATTRIBUTE_BAKARA_LOTUS_1_NORMAL: 90,

        //lotus홀짝
        LEISURE_ATTRIBUTE_LOTUS_CARD_ODDEVEN: 96,
        LEISURE_ATTRIBUTE_LOTUS_CARD_OVERUNDER: 92,
        LEISURE_ATTRIBUTE_LOTUS_CARD_MUNI: 93,
        LEISURE_ATTRIBUTE_LOTUS_CARD_COLOR: 94,
        LEISURE_ATTRIBUTE_LOTUS_DRAGONTIGER_NORMAL: 98,

        LEISURE_ATTRIBUTE_BAKARA_LOTUS_2_NORMAL: 91,


        //mgm바카라
        LEISURE_ATTRIBUTE_BAKARA_MGM_NORMAL: 95,
        //mgm/홀짝
        LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVEN: 97,

        //FX1M
        LEISURE_ATTRIBUTE_FX_1M_ODDEVEN: 100,
        LEISURE_ATTRIBUTE_FX_1M_OVERUNDER: 101,
        LEISURE_ATTRIBUTE_FX_1M_JG_SELLBUY: 102,
        LEISURE_ATTRIBUTE_FX_1M_CG_SELLBUY: 103,
        //FX2M
        LEISURE_ATTRIBUTE_FX_2M_ODDEVEN: 104,
        LEISURE_ATTRIBUTE_FX_2M_OVERUNDER: 105,
        LEISURE_ATTRIBUTE_FX_2M_JG_SELLBUY: 106,
        LEISURE_ATTRIBUTE_FX_2M_CG_SELLBUY: 107,
        //FX3M
        LEISURE_ATTRIBUTE_FX_3M_ODDEVEN: 108,
        LEISURE_ATTRIBUTE_FX_3M_OVERUNDER: 109,
        LEISURE_ATTRIBUTE_FX_3M_JG_SELLBUY: 110,
        LEISURE_ATTRIBUTE_FX_3M_CG_SELLBUY: 111,
        //FX4M
        LEISURE_ATTRIBUTE_FX_4M_ODDEVEN: 112,
        LEISURE_ATTRIBUTE_FX_4M_OVERUNDER: 113,
        LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY: 114,
        LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY: 115,
        //FX5M
        LEISURE_ATTRIBUTE_FX_5M_ODDEVEN: 116,
        LEISURE_ATTRIBUTE_FX_5M_OVERUNDER: 117,
        LEISURE_ATTRIBUTE_FX_5M_JG_SELLBUY: 118,
        LEISURE_ATTRIBUTE_FX_5M_CG_SELLBUY: 119,

        LEISURE_ATTRIBUTE_SUTDA_NORMAL: 120,
        LEISURE_ATTRIBUTE_NINEBALL_WINNUMBER: 121,
        LEISURE_ATTRIBUTE_NINEBALL_ODDEVEN: 122,
        LEISURE_ATTRIBUTE_NINEBALL_BALLCOLOR: 123,

        LEISURE_BET365_LEUGUE_WORLDCUP: "worldcup",

        LEISURE_BET365_LEUGUE_EUROCUP: "eurocup",

        LEISURE_BET365_LEUGUE_SUPERLEAGUE: "superleague",

        LEISURE_BET365_LEUGUE_PREMIERSHIP: "premiership",


        LEAGUE_BET365_BASCKETBALL_WARTER: "warter",

        LEAGUE_BET365_BASCKETBALL_BAKER: "baker",

        LEISURE_ATTRIBUTE_SKY_SPEED_BAKARA_NORMAL: 130,//스피드 바카라
        LEISURE_ATTRIBUTE_SKY_SPEED_BAKARA_PAYERPLAY: 136,//스피드 바카라 페어플레이

        LEISURE_ATTRIBUTE_SKY_CARD_ODDEVEN: 131, //스카이 홀짝
        LEISURE_ATTRIBUTE_SKY_CARD_MUNI: 132,//스카이 홀짝에 히든카드 무니

        LEISURE_ATTRIBUTE_SKY_DICE_ODDEVEN: 133,//주사위 홀짝
        LEISURE_ATTRIBUTE_SKY_DICE_OVERUNDER: 134,//주사위 오버언더
        LEISURE_ATTRIBUTE_SKY_DICE_WINCOLOR: 135,//주사위 높은수자 색상

        //카카오사다리 1분 홀짝 , 좌우측, 3줄4줄
        LEISURE_ATTRIBUTE_KAKAO_SD_1_ODDEVEN: 140,
        LEISURE_ATTRIBUTE_KAKAO_SD_1_LEFT_RIGHT: 141,
        LEISURE_ATTRIBUTE_KAKAO_SD_1_LINE_COUNT: 142,
        LEISURE_ATTRIBUTE_KAKAO_SD_2_ODDEVEN: 143,
        LEISURE_ATTRIBUTE_KAKAO_SD_2_LEFT_RIGHT: 144,
        LEISURE_ATTRIBUTE_KAKAO_SD_2_LINE_COUNT: 145,
        LEISURE_ATTRIBUTE_KAKAO_SD_3_ODDEVEN: 146,
        LEISURE_ATTRIBUTE_KAKAO_SD_3_LEFT_RIGHT: 147,
        LEISURE_ATTRIBUTE_KAKAO_SD_3_LINE_COUNT: 148,

        LEISURE_ATTRIBUTE_STAR_SD_1_ODDEVEN: 149,
        LEISURE_ATTRIBUTE_STAR_SD_1_LEFT_RIGHT: 150,
        LEISURE_ATTRIBUTE_STAR_SD_1_LINE_COUNT: 151,
        LEISURE_ATTRIBUTE_STAR_SD_2_ODDEVEN: 152,
        LEISURE_ATTRIBUTE_STAR_SD_2_LEFT_RIGHT: 153,
        LEISURE_ATTRIBUTE_STAR_SD_2_LINE_COUNT: 154,
        LEISURE_ATTRIBUTE_STAR_SD_3_ODDEVEN: 155,
        LEISURE_ATTRIBUTE_STAR_SD_3_LEFT_RIGHT: 156,
        LEISURE_ATTRIBUTE_STAR_SD_3_LINE_COUNT: 157,
    },

    User: {
        RISK_YES: 1,
        RISK_NOT: 0,
        //유저 타입 - 1: 실유저 , 2:가라유저, 3:관리자유저
        USER_TYPE_NOMAL: 1,
        USER_TYPE_FACK: 2,
        USER_TYPE_MANAGER: 3,
        //회원상태
        STATUS_ENABLE: 1,
        STATUS_DISABLE: 0,
        STATUS_UNAUTHORIZED: 9,//미인증회원
        STATUS_AUTHORIZING: 8,//관리자인증확인중

        BANK_TYPE_NORMAL: 1,//수동
        BANK_TYPE_BANKCARDID: 2,//실통장
        BANK_TYPE_FACK_CARD: 3,//가상계좌1
        BANK_TYPE_FACK_CARD_2: 4,//가상계좌2
        BANK_TYPE_FACK_CARD_3: 5,//가상계좌2
        BANK_TYPE_FACK_CARD_4: 6,//가상계좌2

        SEARCH_BY_USERNAME: "username",
        SEARCH_BY_NICKNAME: "nickname",
        SEARCH_BY_REFERRER: "referrer",
        SEARCH_BY_REALNAME: "applyname",
        SEARCH_BY_REGIP: "regip",
        SEARCH_BY_LASTE_LOGIN_IP: "last_login_ip",
        SEARCH_BY_BENEFICIARY: "beneficiary",
        SEARCH_BY_PHONE: "phone",
        SEARCH_BY_ACNO: "acNo",
    },

    ApplyCash: {
        TYPE_USER: 1,
        TYPE_PARTNER: 2,
        ATYPE_RECHARGE: 1,
        ATYPE_EXCHANGE: 2,

        STATUS_WAITING: 0,
        STATUS_CHECKING: 1,
        STATUS_COMPLITE: 2,
        STATUS_CANCEL: 3,

        APPLY_BONUS_YES1: 1,
        APPLY_BONUS_YES2: 2,
        APPLY_BONUS_NOT: 0,

    },

    CashPointLog: {
        LOG_CASHPOINT_USER: 1,
        LOG_CASHPOINT_PARTNER: 2,

        LOG_CASHPOINT_CASH: 1,
        LOG_CASHPOINT_POINT: 2,
        LOG_TYPE_COUPON: 3,


        LOG_CASHPOINT_PLUS: 1,
        LOG_CASHPOINT_MINUS: 2,

        LOG_CASHPOINT_PROTO: 1,
        LOG_CASHPOINT_LEISURE: 2,


        LOG_CASHPOINT_TYPE_PLUS_POINT_BET_LEISURE_AGENT_2_USER: 23,//총판->유저 -> 미니게임 베팅시 롤링을준 금액
        LOG_CASHPOINT_TYPE_PLUS_POINT_BET_CASINO_AGENT_2_USER: 29,//총판->유저 -> 카지노 베팅시 롤링을준 금액

        LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_DAILI: 46,//부본사->총판 머니이동금액


        LOG_CASHPOINT_TYPE_MANAGER: 99,
        LOG_CASH_TYPE_MANAGER_ADDCASH: 100,
        LOG_CASH_TYPE_MANAGER_MINUSCASH: 101,
        LOG_POINT_TYPE_MANAGER_ADDPOINT: 102,
        LOG_POINT_TYPE_MANAGER_MINUSPOINT: 103,
    },

    SportsBet: {
        SEARCH_BY_NICKNAME: "nickname",
        SEARCH_BY_GAMEID: "gameid",
        SEARCH_BY_BETINFOID: "betinfoid",
        SEARCH_BY_BETIP: "ip",

        /**
         * 0:정산대기중, 1:정산완료
         */
        STATUS_LIQUIDATOIN_WAITING: 0,
        STATUS_LIQUIDATOIN_COMPLITE: 1,

        /*
         * 0:진행중, 1:적중, 2:미적중, 3:적중특례, 4:취소
         */
        BET_RESULT_WAITING: 0,
        BET_RESULT_WIN: 1,
        BET_RESULT_LOSE: 2,
        BET_RESULT_SPECIALCASE: 3,
        BET_RESULT_CANCEL: 4,

        STATUS_WAITING: 0,
        STATUS_COMPLITE: 1,

        /**
         * 베팅내역 파트너정산완료여부
         */
        BET_GIVEBACK_NOT: 0,
        BET_GIVEBACK_YES: 1,
        /**
         * 베팅내역 유저가 취소한베팅인지 판단
         */
        BET_CANCELBYUSER_NOT: 0,
        BET_CANCELBYUSER_YES: 1,

        BET_TYPE_CROSS: 1,
        BET_TYPE_1X2: 2,
        BET_TYPE_HO: 3,
        BET_TYPE_SPECIAL: 4,
        BET_TYPE_LIVE: 5,
        BET_TYPE_INPLAY: 99,
        BET_TYPE_SIMULATION_SOCCER: 6,
    },
    LogLogin: {
        //로그인시 사용
        LOG_LOGIN_TYPE_USER: 1,
        LOG_LOGIN_TYPE_MANAGER: 2,
        LOG_LOGIN_TYPE_PARTNER: 3,
        SEARCH_BYFEILD_NICKNAME: "nickname",
        SEARCH_BYFEILD_IP: "ip",
    },
    Message: {
        TYPE_PRIVATE: 1,
        TYPE_PUBLIC: 2,
        MESSAGE2WHO_USER: "user",
        MESSAGE2WHO_ALLUSER: "allUser",
        MESSAGE2WHO_RANK: "rank",
        MESSAGE2WHO_GENERALAGENT: "generalAgent",
        MESSAGE2WHO_AGENT: "agent",
        MESSAGE2WHO_BANKTYPE: "banktype",
    },
    CustomercenterTemplateType: {
        TYPE_NORMAL: 1, //일반답변
        TYPE_BANK_CARD: 2, //찐계좌정보
        TYPE_FACK_BANK_CARD: 3, //가상계좌정보1
        TYPE_FACK_BANK_CARD_2: 4, //가상계좌정보2
        TYPE_FACK_BANK_CARD_3: 5, //가상계좌정보2
        TYPE_FACK_BANK_CARD_4: 6, //가상계좌정보2
    },
    Tgame365: {
        LEISURE_KIND_TGAME365_CASINO: 90, //Tgame 카지노
        LEISURE_KIND_TGAME365_SLOT: 91, //Tgame 슬롯
        LEISURE_KIND_TGAME365_FISH: 92, //Tgame 슬롯
        LEISURE_KIND_TGAME365_FW: 93, //Tgame 토큰휠
        LEISURE_KIND_TGAME365_HILOW: 94, //Tgame 하이로
        LEISURE_KIND_TGAME365_SNAIL3: 95, //Tgame 달팽이3
        LEISURE_KIND_TGAME365_SNAIL4: 96, //Tgame 달팽이4
        LEISURE_KIND_TGAME365_RUOLLET: 97, //Tgame 룰렛
        REQUEST_TYPE_BET: 1,
        REQUEST_TYPE_FISH_RECHARGE: 10,
        REQUEST_TYPE_FISH_EXCHANGE: 20,
    },
    ReferreCode: {
        CODE_TYPE_USER: 1,
        CODE_TYPE_PARTNER: 2,
        CODE_TYPE_MANAGER: 3,

        SEARCH_BYFEILD_USER: "user",
        SEARCH_BYFEILD_PARTNER: "partner",
        SEARCH_BYFEILD_CODE: "scode",

    },
    Parnter: {
        /**
         * 총판
         */
        PATNER_TYPE_GENERALAGENT: 1,
        /**
         * 대리점
         */
        PATNER_TYPE_AGENT: 2,

        PATNER_GENERALAGENT_NOID: 0,
        /**
         * byField
         */
        SEARCH_BY_ACCOUNT: "account",
        SEARCH_BY_USERNAME: "username",
        SEARCH_BY_NICKNAME: "nickname",
    },
    Customercenter: {
        STATUS_UNCONFIRMED: 0,
        STATUS_CHECKED: 1,
        STATUS_REPLYED: 2,
    },
    Board: {
        TYPE_FREE_BOARD: 1,
        TYPE_NOTICE: 2,
        TYPE_COMMENT: 3,
        TYPE_EVENT: 4,

        NOTICE_TYPE_IN_FREEBOARD: 1,
        NOTICE_TYPE_LINE: 2,
        NOTICE_TYPE_IN_BETFORM: 3,

    },
    Modified: {
        MTYPE_USER: 1,
        MTYPE_GAME: 2,
        MTYPE_SITE: 3,
        MTYPE_PARTNER: 4,
        MTYPE_IP: 5,
        MTYPE_MANAGERPWD: 6,

        SEARCH_BY_USERNAME: "username",
        SEARCH_BY_IP: "ip",
    },
    CrossRule: {
        RULE_TYPE_FULLTIME: 1,
        RULE_TYPE_HALFTIME: 2,
    },

    Popup: {
        PLATFORM_PC: 1,
        PLATFORM_MOBILE: 2,
        TYPE_CONTENT: 1,
        TYPE_IMAGE: 2,
    },
    IPType: {
        IP_TYPE_BLOCK: 1,
        IP_TYPE_ALLOWE_MANAGER_LOGIN: 2,
    }


}