<template>
  <div>
    <div class="header">
      <div class="hd1">
        <div class="hdsectionup">
          <div class="s1">
            <div class="mobilemenuicon"  @click="showMobileTopMenu">
              <div><img src="../assets/images/icon/mobile/menu/icon-menu.png" alt=""></div>
            </div>
          </div>
          <router-link tag="div" to="/main" class="s2"><img src="../assets/images/logo/balam/logo01.png" alt=""></router-link>
          <div class="s3">
            <div class="pcitems" v-if="!$store.state.isLogin">
              <div class="item">
                <input type="text" placeholder="아이디" class="mr5" v-model="user.username" @keyup.enter="doLogin"></input>
                <input type="password" placeholder="비밀번호" v-model="user.passwd" @keyup.enter="doLogin"></input>
              </div>
              <div  class="item">
                <button class="mr3" @click="showRegisterPanel">회원가입</button>
                <button @click="doLogin">로그인</button>
              </div>


            </div>
            <div class="useritems" v-if="$store.state.isLogin">
              <div class="userinfo"><img :src="$store.state.userInfo.rank.imageUrl" width="18">{{$store.state.userInfo.nickname}}</div>
              <div class="userinfo">머니: <font style="color: #ffa604">{{$store.state.userInfo.cash|comma}}</font></div>
              <div class="userinfo">포인트: <font style="color: #ffa604">{{$store.state.userInfo.userPoint|comma}}</font></div>
              <div class="userinfo">카지노: <font style="color: #ffa604">{{$store.state.userInfo.casinocash|comma}}</font></div>
              <button class="mr3 logout" @click="logout">로그아웃</button>
            </div>
            <div class="mobilecartmenu" @click="rightBarHandle()">
              <div v-if="$store.state.isLogin"><img src="../assets/images/icon/mobile/menu/icon-cart.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <div class="hd2">
          <div class="menu_warp">
            <router-link tag="div" to="/recharge" class="menu">입금</router-link>
            <router-link tag="div" to="/exchange" class="menu">출금</router-link>
            <router-link tag="div" to="/sbetinfo" class="menu">베팅내역</router-link>
            <router-link tag="div" to="/notice" class="menu">공지사항</router-link>
            <router-link tag="div" to="/event" class="menu">이벤트</router-link>
            <router-link tag="div" to="/customer_center" class="menu">고객센터</router-link>
            <router-link tag="div" to="/culcheck" class="menu">출석부</router-link>
          </div>

      </div>
    </div>

    <!--모바일 메뉴-->
    <mobile-top-menu></mobile-top-menu>
    <main-taining-comp></main-taining-comp>
  </div>

</template>

<script>
import sportsConst from "../common/sportsConst";
import leisureConst from "../common/leisureConst";
import MobileTopMenu from "./mobile/MobileTopMenu";
import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
import {changePoint, getLineMessage, getSiteStatus, getUserInfo, login, logout} from "../network/userRequest";
import {
  INIT_USERINFO,
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_USER_INFO
} from "../store/mutation-types";
import Clock from "./Clock";
import RegisterComp from "./RegisterComp";
import NotMemeberCustomerComp from "./NotMemeberCustomerComp";
import MainTainingComp from "./MainTainingComp";
import {getAccountBalance} from "../network/casinoHonorRequest";

export default {
  name: "TopbarComp",
  components: {MainTainingComp, NotMemeberCustomerComp, RegisterComp, Clock, MobileTopUserInfo, MobileTopMenu},
  data() {
    return {
      leisureConst,
      cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
      handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
      special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
      live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
      lineMessage: '',
      user: {
        username: '',
        passwd: '',
      },
    }
  },
  methods: {
    changePoint() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.initUserInfo()
        }
      })
    },
    initUserInfo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.$store.dispatch('actionUserInfo')
      setTimeout(() => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
      }, 1000)
    },
    refreshCasinoCash() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    rightBarHandle() {
      this.$bus.$emit('leftBarHandle', false)
      this.$bus.$emit('showMobileTopMenu', false)
      this.$bus.$emit('rightBarHandle', true)
    },
    showLoginPanel() {
      if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
        this.$store.state.isShowLoginPanel = true
        this.$store.state.isShowRegisterPanel = false
      }
    },
    showRegisterPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
      }
    },
    showBetInfoPanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowLeisureSelectPanel = false;
      this.$store.state.isShowSportsSelectPanel = false;
      this.$store.state.isShowBetInfoSelectPanel = true
    },
    changeSportsDisplay(disp) {
      //SportsOriginal.vue -> $on
      //SportsLinksComp.vue ->on
      this.$bus.$emit('sportsDisplayChanged', disp);
    },
    showMobileTopMenu() {
      //모바일 메뉴아이콘 클릭시
      //MobileTopMenu.vue에서 $on
      //this.$bus.$emit('mobileTopMenuActive')
      this.$bus.$emit('leftBarHandle', false)
      this.$bus.$emit('rightBarHandle', false)
      this.$bus.$emit('showMobileTopMenu', true)
    },
    showMobileUserInfo() {
      //모바일 유저아이콘 클릭시
      //MobileTopUserInfo.vue에서 $on
      this.$bus.$emit('mobileTopUserInfoActive')
    },
    doLogin() {
      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          getUserInfo().then(res => {
            this.$store.state.isLogin = true;
            const userInfo = res.data.data
            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            window.sessionStorage.setItem("isLogin", 'true');
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.user.username = '';
            this.user.passwd = '';
            this.$router.push('/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    logout() {
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: '로그아웃',
      }).then((res) => {
        if (res.value) {
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$router.replace("/main")
          this.$store.state.uncheckedMessageCount = 0;
          this.$store.state.isLogin = false;
          logout().then(res => {
            this.$router.replace("/main")
          });

        }
      })

    },
    toMain() {
      this.$router.replace("/main")
    },
    getLineMessage() {
      getLineMessage().then(res => {
        if (res.data.success) {
          let lines = res.data.data;
          lines.map(line => {
            this.lineMessage = this.lineMessage + '   ' + line.content
          })
        }

      })
    },
    browser() {
      let u = navigator.userAgent
      let app = navigator.appVersion
      return {         //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE
        presto: u.indexOf('Presto') > -1, //opera
        webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
        iPhone: u.indexOf('iPhone') > -1, //
        iPad: u.indexOf('iPad') > -1, //
        webApp: u.indexOf('Safari') == -1//
      }
    },
    showNotMemberCustomerPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = false
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowNotMemberCustomerePanel = true
      }
    },
  },
  created() {
    if (this.$store.state.isLogin == false && this.$route.path.indexOf('main') == -1) {
      /* this.$swal({
           title: '',
           html: '<div>\n' +
               '                    <p style="font-size: 16px;color: #ff0000">로그인이 필요한 서비스입니다</p>' +
               '                </div>',
           type: 'warning',
           background: '#373735',
           confirmButtonText: ' 확인 ',
           cancelButtonText: '아니오',
           showCancelButton: false,
           showConfirmButton: true
       })*/
      this.$router.replace("/main")
    }
    getSiteStatus().then(res => {
      if (!res.data.success) {
        logout()
        this.$store.state.isMaintaining = true;
        this.$store.state.maintainingMessage = res.data.msg
        this.$router.replace("/main")
      } else {
        this.$store.state.isMaintaining = false;
        this.$store.state.maintainingMessage = ''
      }
    })
    this.$store.state.ismobile = this.browser().mobile
    //랭킹,보너스,베팅금액,당첨금액 등 설정요청
    this.$store.dispatch('actionCommonConfig')
    this.getLineMessage();
    if (this.$store.state.isLogin) {
      this.$store.state.uncheckedMessageCount = 0;
      this.$store.dispatch('actionUserInfo')
      this.$store.dispatch('actionUnCheckedMessageCount')
      setTimeout(() => {
        getAccountBalance().then(res => {
          this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
        })
      }, 500)
    } else {
      this.$store.commit(INIT_USERINFO);
    }
  },
}
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header .hd1 {
  width: 100%;
  height: 150px;
  background: url("../assets/images/bg/balam/main_top_bg.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .hd2 {
  width: 100%;
  height: 55px;
  border-top: 1px solid #393939;
  background: linear-gradient(180deg, #111111, #050505);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .hd2 .menu_warp{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 960px;
}

.header .hd2  .menu_warp .menu{
  font-size: 16px;
  text-align: right;
  padding: 5px 0;
  cursor: pointer;
  margin-left: 20px;
}
.header .hd2 .menu_warp .menu:hover{
  color: #ffa604;
  transition: all .4s;
}

.header .hd1 .hdsectionup {
  width: 960px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: #fff9ff;
}

.header .hd1 .hdsectionup .s1 {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.header .hd1 .hdsectionup .s1 .mobilemenuicon{
  display: none;
}

.header .hd1 .hdsectionup .s2 {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header .hd1 .hdsectionup .s2 img {
  width: 214px;
  height: 95px;
}

.header .hd1 .hdsectionup .s3 {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.header .hd1 .hdsectionup .s3 .mobilecartmenu {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header .hd1 .hdsectionup .s3 .pcitems {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
}
.header .hd1 .hdsectionup .s3 .pcitems .item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
}

.header .hd1 .hdsectionup .s3 .useritems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 25px;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.header .hd1 .hdsectionup .s3 .logout{
  width: 110px;
  border-radius: 50px;
  text-align: center;
  height: 28px;
  border: 2px solid #bdbdbd;
  color: #e1dfe1;
  position: absolute;
  top: -35px;
}
.header .hd1 .hdsectionup .s3 .useritems .userinfo{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #282828;
  border-radius: 5px;
  margin: 0 1px;
  padding: 0 5px;
  height: 28px;
}
.header .hd1 .hdsectionup .s3 .useritems .userinfo img{
    width: 20px;
  margin-right: 2px;
}

.header .hd1 .hdsectionup .s3 .pcitems input {
  width: 120px;
  border-radius: 50px;
  text-align: center;
  height: 28px;
  border: 2px solid #bdbdbd;
  color: #e1dfe1;
}

.header .hd1 .hdsectionup .s3 .pcitems button {
  width: 120px;
  border-radius: 50px;
  text-align: center;
  height: 28px;
  border: 2px solid #bdbdbd;
  color: #e1dfe1;
  background: linear-gradient(180deg, #030303, #403e3f);
}

.header .hd1 .hdsectionup .s3 .pcitems .mr3 {
  margin-right: 3px;
}

.header .hd1 .hdsectionup .s3 .pcitems .mr5 {
  margin-right: 5px;
}
.header .hd1 .hdsectionup .s3 .pcitems .logout{
  height: 24px;
  width: 85px;
  margin-left: 5px;
}


@media screen and (max-width: 1024px) {
  .header .hd1 {
    height: 115px;
    border-bottom: 2px solid #cccccc;
  }


  .header .hd1 .hdsectionup .s1 {
    width: 20%;
  }
  .header .hd1 .hdsectionup .s1 .mobilemenuicon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header .hd1 .hdsectionup .s1 .mobilemenuicon img {
    width: 40px;
    height: 40px;
  }

  .header .hd1 .hdsectionup .s2 {
    width: 60%;
  }

  .header .hd1 .hdsectionup .s2 img {
    width: 140px;
    height: 40px;
  }
  .header .hd1 .hdsectionup .s3{
    width: 20%;
    justify-content: center;
    align-items: center;
  }

  .header .hd1 .hdsectionup .s3 .pcitems {
    display: none;
  }
  .header .hd1 .hdsectionup .s3 .useritems{
    display: none;
  }

  .header .hd1 .hdsectionup .s3 .mobilecartmenu {
    display: flex;
  }

  .header .hd1 .hdsectionup .s3 .mobilecartmenu img {
    width: 40px;
    height: 40px;
  }

  .header .hd2 {
    display: none;
  }

}
</style>

