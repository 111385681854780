<template>
    <div class="right_button_box">
        <div class="btns">
            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}"><i class="fa fa-plus-square"></i> 입금</router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/exchange', query: {t: new Date().getTime()}}"><i class="fa fa-minus-square"></i> 출금</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/user_info', query: {t: new Date().getTime()}}"><i class="fa fa-minus-square"></i> 회원정보</router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/sbetinfo', query: {t: new Date().getTime()}}"><i class="fa fa-minus-square"></i> 베팅내역</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/culcheck', query: {t: new Date().getTime()}}"><i class="fa fa-calendar"></i> 출석부</router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/event', query: {t: new Date().getTime()}}"><i class="fa fa-gift"></i> 이벤트</router-link>
            <router-link tag="button" class="btn-1" to="" @click.native="changePoint"><i class="fa fa-circle"></i> 포인트전환</router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/user_info', query: {t: new Date().getTime()}}"><i class="fa fa-user-circle"></i>  회원정보</router-link>

        </div>

    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {changePoint} from "../network/userRequest";

    export default {
        name: "RightButtonsComp",
        methods: {
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            initUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                this.$store.dispatch('actionUserInfo')
                setTimeout(()=>{
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                },1000)
            },
        }
    }
</script>

<style scoped>
    .right_button_box{
        width: 100%;
        margin: 8px 0;
        padding: 0px 10px;
    }
    .right_button_box .btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 3px 0;

    }
    .right_button_box .btns .btn-1{
        width: 49.5%;
        height: 26px;
        background-color: var(--loginBtnBg);
        color: #fff9ff;
        margin: 1px 0;
        border-radius: 1px;
        font-weight: normal;
        font-size: 12px;
    }
    .right_button_box .btns .btn-2{
        width: 49.5%;
        height: 26px;
        background-color: var(--regBtnBg);
        color: #fff9ff;
        margin: 1px 0;
        border-radius: 1px;
        font-weight: normal;
        font-size: 12px;
    }
    @media screen and (max-width: 1024px) {
        .right_button_box{
            --display: none!important;
        }
    }
</style>