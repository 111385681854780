import { render, staticRenderFns } from "./SportsSelectComp.vue?vue&type=template&id=a92d81fc&scoped=true&"
import script from "./SportsSelectComp.vue?vue&type=script&lang=js&"
export * from "./SportsSelectComp.vue?vue&type=script&lang=js&"
import style0 from "./SportsSelectComp.vue?vue&type=style&index=0&id=a92d81fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a92d81fc",
  null
  
)

export default component.exports