<template>
  <div>
    <popup-comp></popup-comp>
    <!--읽지 않은 메세지-->
    <message-comp></message-comp>
    <div class="footer">
      <div class="fline">
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/1x2game.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/7mojos.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/333gaming.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/abslute.png"></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/allbet.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/amigo.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/asiagame.png" alt=""></div>
      </div>
      <div class="fline">
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/astar.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/avatarux.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/betgamestv.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/bgaming.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/blueprint.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/boominggames.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/boongo.png" alt=""></div>
      </div>
      <div class="fline">
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/caleta.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/cq9.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/dblivecasino.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/dowin.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/dragoonsoft.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/yggdrasil.png" alt=""></div>
        <div class="fdiv"><img src="../assets/images/logo/thirdpartylogo/rubyplay.png" alt=""></div>
      </div>




    </div>
  </div>


</template>

<script>
import MessageComp from "./MessageComp";
import PopupComp from "./PopupComp";

export default {
  name: "FootComp",
  components: {PopupComp, MessageComp},
  created() {
  }
}
</script>

<style scoped>
.footer {
  width: 960px;
  background: rgb(0, 0, 0);
  margin: 1px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.footer .fline {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: 100%;
}

.footer .fline .fdiv {
  width: 12.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .fline .fdiv img {
  width: 95%;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .footer {
    width: 100% !important;
    flex-wrap: wrap !important;
  }

  .footer .fline .fdiv {
    width: 14%;
  }
}


</style>