<template>
    <router-link tag="div" to="" class="overlay" :class="{'showSelectPannel':$store.state.isShowBetInfoSelectPanel}"
                 @click.native="closePanel">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="selectButtons">
                    <router-link to="/sbetinfo" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/sbetselect.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure_betinfo" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/lbetselect.jpg" alt="">
                    </router-link>
                </div>

            </div>
        </div>

    </router-link>
</template>

<script>

    export default {
        name: "BetInfoSelectComp",
        data() {
            return {}
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.$store.state.isShowSportsSelectPanel = false;
                this.$store.state.isShowLeisureSelectPanel = false;
                this.$store.state.isShowBetInfoSelectPanel = false;
            },
        },
        created() {
        },
        computed: {}
    }
</script>

<style scoped>



    .selectButtons {
        width: 950px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        --background: #fff9ff;
        box-sizing: border-box;
        padding: 5px;
    }
    .selectButtons div{
        box-sizing: border-box;
        padding: 5px;
    }

    .selectButtons img {
        width: 100%;
        border: 1px solid #828282;
        border-radius: 5px;
    }


    .showSelectPannel {
        transform: translateY(0%);
    }

    @media screen and (max-width: 1024px) {
        .selectButtons {
            width: 100% !important;
            justify-content: center;
            align-items: center;
        }
        .selectButtons div {
            width: 49% !important;
        }
        .overlay .overlay-warp .overlay-open{
            width: 98% !important;
        }

    }

</style>