import {getLeisureGameKindConfig, getLotusToken, getOnBetLeisureGames} from "../network/leisureRequest";
import leisureConst from "./leisureConst";
import sportsConst from "./sportsConst";
import inplayConst from "./inplayConst";
import {
    RECEIVE_HIDE_LOADING,
    RECEIVE_INPLAY_CART_ITEM,
    RECEIVE_INPLAY_CART_ITEM_DEL, RECEIVE_SHOW_LOADING,
    RECEIVE_SPORTS_CART_ITEM,
    RECEIVE_SPORTS_CART_ITEM_DEL
} from "../store/mutation-types";
import {mapGetters} from "vuex";
import {setPosition} from "../network/userRequest";

export const loginRegisterMixin = {
    computed: {
        testRegxUserName() {
            return /^[0-9a-zA-Z]{3,16}$/.test(this.user.username);
        },
        testRegxPasswd() {
            return /^.{4,29}$/.test(this.user.passwd);
        },
        testRegxNickname() {
            return /^[가-힣]{2,6}$/.test(this.user.nickname)
        }
    }
}
export const checkLoginMinxin = {
    methods: {
        checkLogin() {
            if (!this.$store.state.isLogin) {
                this.$store.state.isShowLoginPanel = true;
                this.$router.push("/login")
                return false;
            }
            return true;
        }
    }
}

export const leisureGameMixin = {
    data() {
        return {
            showMasker: true,
            leisureConst,
            sportsConst,
            leisureGameInfo: {
                betStopLimitSecond: 0,
                leisureGame: null,
                leisureSportsGames: [],
                now: null,
                beforEndSecond: 10000
            },
            loadingSecond: 10000,
            attrConfig: [],
            //사디리
            oddevenConfig: {},
            leftrightConfig: {},
            lineConfig: {},
            overunderConfig: {},
            //달팽이
            winnumberConfig: {},
            //fx
            jonggasellbuyConfig: {},
            cegasellbuyConfig: {},
            //bet365가상축구
            spConfig: {},
            souConfig: {},
            spGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//승무패
            souGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//오버언더
            //bet365가상개경주
            dograceConfig: {},
            horseraceConfig: {},
            dograceGame: {bedang1: 0, bedang2: 0, bedang3: 0, bedang4: 0, bedang5: 0, bedang6: 0},
            horseraceGame: {
                bedang1: 0,
                bedang2: 0,
                bedang3: 0,
                bedang4: 0,
                bedang5: 0,
                bedang6: 0,
                bedang7: 0,
                bedang8: 0,
                bedang9: 0,
                bedang10: 0,
                bedang11: 0,
                bedang12: 0,
                bedang13: 0,
                bedang14: 0,
                bedang15: 0
            },

            horseraceGameList: [],

            //섯다
            normalConfig: {},
            //나인볼
            colorConfig: {},
            muniConfig: {},

            //파워볼
            poddevenConfig: {},
            poverunderConfig: {},
            psectionConfig: {},
            pnumberConfig: {},
            poddevenoverunderConfig: {},
            //일반볼
            noddevenConfig: {},
            noverunderConfig: {},
            nsectionConfig: {},
            nbmsConfig: {}, //일반볼 소,중,대
            noddevenoverunderConfig: {},
            noddevenbmsConfig: {},
            croConfig:{},
            crossConfig:{},

            selectedInfo: {
                selectedAttr: 0,
                selectedGameId: 0,
                selectedWay: 0,
                seletedOdd: 0,
                selectText: '',
                config: {maxBetCash: 0, minBetCash: 0, maxWinCash: 0}
            },
            lotusmode: 'main'
        }
    },
    methods: {
        selected(attr, gameId, way, odd, config, selectText) {
            this.selectedInfo.selectedAttr = attr
            this.selectedInfo.selectedGameId = gameId
            this.selectedInfo.selectedWay = way
            this.selectedInfo.seletedOdd = odd
            this.selectedInfo.selectText = selectText
            this.selectedInfo.config = config
        },
        refreshTimer() {
            this.initLeisureGame()
        },
        timerStop() {
            this.showMasker = true
            //sky경기는 초를 더 늘궈줌
            if (this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA
                || this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA2) {
                this.loadingSecond = this.loadingSecond + 10000
            }
            if (this.kind === leisureConst.LEISURE_KIND_SKY_ODDEVEN) {
                this.loadingSecond = this.loadingSecond + 5000
            }
            setTimeout(() => {
                this.initLeisureGame();
            }, this.loadingSecond)
        },
        resetLeiserGameSelected() {
            this.selectedInfo.selectedAttr = 0
            this.selectedInfo.selectedGameId = 0
            this.selectedInfo.selectedWay = 0
            this.selectedInfo.seletedOdd = 0
            this.selectedInfo.selectText = ''
        },
        initLeisureGame() {
            if (this.kind !== leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA
                && this.kind !== leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA2
                && this.kind !== leisureConst.LEISURE_KIND_SKY_ODDEVEN
                && this.kind !== leisureConst.LEISURE_KIND_SKY_DICE) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
            }
            getOnBetLeisureGames(this.kind, this.$moment().millisecond()).then(res => {
                if (res.data.success) {
                    this.resetLeiserGameSelected();
                    this.$set(this.leisureGameInfo, 'betStopLimitSecond', res.data.data.betStopLimitSecond)
                    this.$set(this.leisureGameInfo, 'leisureGame', res.data.data.leisureGame)
                    this.$set(this.leisureGameInfo, 'leisureSportsGames', res.data.data.leisureSportsGames)
                    this.$set(this.leisureGameInfo, 'now', res.data.data.now)

                    //Bet365가상축구일 경우
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER
                        || this.kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER) {
                        this.leisureGameInfo.leisureSportsGames.forEach((game) => {
                            if (game.sportsKind === sportsConst.GAME_KIND_1X2) {
                                this.spGame = game;
                            }
                            if (game.sportsKind === sportsConst.GAME_KIND_OVERUNDER) {
                                this.souGame = game;
                            }

                        })
                    }
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                        || this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK) {
                        this.dograceGame = this.leisureGameInfo.leisureGame
                        this.dograceGame.bedang1 = this.dograceGame.bedang1.toFixed(2)
                        this.dograceGame.bedang2 = this.dograceGame.bedang2.toFixed(2)
                        this.dograceGame.bedang3 = this.dograceGame.bedang3.toFixed(2)
                        this.dograceGame.bedang4 = this.dograceGame.bedang4.toFixed(2)
                        this.dograceGame.bedang5 = this.dograceGame.bedang5.toFixed(2)
                        this.dograceGame.bedang6 = this.dograceGame.bedang6.toFixed(2)
                    }

                    if (this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_2
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_3
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_4) {

                        this.horseraceGame = this.leisureGameInfo.leisureGame
                        let names = null;
                        if (this.horseraceGame.names) {
                            names = JSON.parse(this.horseraceGame.names)
                        }
                        this.horseraceGameList = []
                        if (this.horseraceGame.bedang1) {
                            this.horseraceGameList.push({
                                'key': 1,
                                'val': this.horseraceGame.bedang1.toFixed(2),
                                'name': names == null ? '' : names[0]
                            })
                        }
                        if (this.horseraceGame.bedang2) {
                            this.horseraceGameList.push({
                                'key': 2,
                                'val': this.horseraceGame.bedang2.toFixed(2),
                                'name': names == null ? '' : names[1]
                            })
                        }
                        if (this.horseraceGame.bedang3) {
                            this.horseraceGameList.push({
                                'key': 3,
                                'val': this.horseraceGame.bedang3.toFixed(2),
                                'name': names == null ? '' : names[2]
                            })
                        }
                        if (this.horseraceGame.bedang4) {
                            this.horseraceGameList.push({
                                'key': 4,
                                'val': this.horseraceGame.bedang4.toFixed(2),
                                'name': names == null ? '' : names[3]
                            })
                        }
                        if (this.horseraceGame.bedang5) {
                            this.horseraceGameList.push({
                                'key': 5,
                                'val': this.horseraceGame.bedang5.toFixed(2),
                                'name': names == null ? '' : names[4]
                            })
                        }
                        if (this.horseraceGame.bedang6) {
                            this.horseraceGameList.push({
                                'key': 6,
                                'val': this.horseraceGame.bedang6.toFixed(2),
                                'name': names == null ? '' : names[5]
                            })
                        }
                        if (this.horseraceGame.bedang7) {
                            this.horseraceGameList.push({
                                'key': 7,
                                'val': this.horseraceGame.bedang7.toFixed(2),
                                'name': names == null ? '' : names[6]
                            })
                        }
                        if (this.horseraceGame.bedang8) {
                            this.horseraceGameList.push({
                                'key': 8,
                                'val': this.horseraceGame.bedang8.toFixed(2),
                                'name': names == null ? '' : names[7]
                            })
                        }
                        if (this.horseraceGame.bedang9) {
                            this.horseraceGameList.push({
                                'key': 9,
                                'val': this.horseraceGame.bedang9.toFixed(2),
                                'name': names == null ? '' : names[8]
                            })
                        }
                        if (this.horseraceGame.bedang10) {
                            this.horseraceGameList.push({
                                'key': 10,
                                'val': this.horseraceGame.bedang10.toFixed(2),
                                'name': names == null ? '' : names[9]
                            })
                        }
                        if (this.horseraceGame.bedang11) {
                            this.horseraceGameList.push({
                                'key': 11,
                                'val': this.horseraceGame.bedang11.toFixed(2),
                                'name': names == null ? '' : names[10]
                            })
                        }
                        if (this.horseraceGame.bedang12) {
                            this.horseraceGameList.push({
                                'key': 12,
                                'val': this.horseraceGame.bedang12.toFixed(2),
                                'name': names == null ? '' : names[11]
                            })
                        }
                        if (this.horseraceGame.bedang13) {
                            this.horseraceGameList.push({
                                'key': 13,
                                'val': this.horseraceGame.bedang13.toFixed(2),
                                'name': names == null ? '' : names[12]
                            })
                        }
                        if (this.horseraceGame.bedang14) {
                            this.horseraceGameList.push({
                                'key': 14,
                                'val': this.horseraceGame.bedang14.toFixed(2),
                                'name': names == null ? '' : names[13]
                            })
                        }
                        if (this.horseraceGame.bedang15) {
                            this.horseraceGameList.push({
                                'key': 15,
                                'val': this.horseraceGame.bedang15.toFixed(2),
                                'name': names == null ? '' : names[14]
                            })
                        }
                    }
                    try {
                        this.$refs.ltimer.startTimeCount();
                    } catch (e) {

                    }

                    this.showMasker = false

                } else {
                    this.showMasker = true
                    try {
                        //this.$refs.ltimer.setSequenceText(res.data.msg)
                    } catch (e) {
                        console.log(e)
                    }
                    if (this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA
                        || this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA2
                        || this.kind === leisureConst.LEISURE_KIND_SKY_ODDEVEN
                        || this.kind === leisureConst.LEISURE_KIND_SKY_DICE) {
                        //this.$refs.ltimer.setSequenceText = "경기 초기화 중입니다"
                        setTimeout(this.initLeisureGame, 1000);
                    }
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        },
        initKindConfig(kind) {
            getLeisureGameKindConfig(kind).then(res => {
                if (res.data.success) {
                    this.loadingSecond = res.data.data
                    this.loadingSecond = (this.loadingSecond + 5) * 1000
                }
            })
        },
        getLotusToken(gameCode) {

            getLotusToken(gameCode).then(res => {
                this.token = res.data.data
                let domn = ''
                if (gameCode === 'oe') {
                    domn = 'http://stream.lotusgm02.com'
                }
                if (gameCode === 'dt') {
                    domn = 'http://stream02.lotusgm02.com'
                }
                if (gameCode === 'baca') {
                    domn = 'http://stream03.lotusgm02.com'
                }
                if (this.$store.state.ismobile) {
                    this.lotusmode = 'mid'
                } else {
                    this.lotusmode = 'mid'
                }
                this.lotusmode = 'mid'
                this.iframDomain = domn + '?gType=' + this.lotusmode + '&token=' + this.token
                setTimeout(this.send2Lotus, 1000 * 3)
                this.sessionTimer = setInterval(this.send2Lotus, 1000 * 60);
            })
        },
        send2Lotus() {

            let msg = {game: this.gameCode, domain: window.origin};

            try {
                let domn = ''
                if (this.gameCode === 'oe') {
                    domn = 'http://stream.lotusgm02.com'
                }
                if (this.gameCode === 'dt') {
                    domn = 'http://stream02.lotusgm02.com'
                }
                if (this.gameCode === 'baca') {
                    domn = 'http://stream03.lotusgm02.com'
                }
                document.stream_frm.postMessage(msg, domn);
            } catch (e) {

            }

        }
    },
    created() {
        this.initLeisureGame()
        this.initLeisureGameArttributeConfig()
        this.$bus.$on('leisureBetSuccess', () => {
            this.resetLeiserGameSelected()
        })
    }
}


export const sportsFromBetAPI = {
    props: {
        item: null,
    },
    data() {
        return {
            inplayConst,
            sportsConst
        }
    },
    computed: {},
    methods: {
        selectItem(game, way, text, id) {

            if (way === sportsConst.WAY_HOME && (parseInt(game.homeSu) === 1 || game.homeOdds <= 1)) {
                return false;
            }

            if (way === sportsConst.WAY_AWAY && (parseInt(game.awaySu) === 1 || game.awayOdds <= 1)) {
                return false;
            }
            if (way === sportsConst.WAY_DRAW && (parseInt(game.drawSu) === 1 || game.drawOdds <= 1)) {
                return false;
            }
            this.$bus.$emit('addInplayGame2BetCart', {game: game, way: way, text: text, selectId: id});
        },
    }
}

export const postionMixin = {
    created() {
        setPosition(this.position)
        window.scrollTo(0, 0);
    }
}

