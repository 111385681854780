<template>
  <div class="overlay" :class="{'showLoginPannel':$store.state.isShowLoginPanel && !$store.state.isLogin}">
    <div class="overlay-warp">
      <div class="overlay-open">

        <div class="login-warp">
          <div class="login-head">
            <h4>로그인</h4>
            <div class="login-panel-close" @click="closePanel">
              <img src="../assets/images/icon/common/close1.svg"/>
            </div>
          </div>
          <div class="login-cont">
            <div class="login-d">
              <div class="login-left" v-if="!$store.state.isMaintaining">
                <div class="login-logo">
                  <img src="../assets/images/logo/balam/logo01.png" alt="">
                </div>
                <div class="login-form">
                  <div class="form-field">
                    <input type="text" placeholder="아이디" v-model="user.username" @keyup.enter="doLogin"/>
                  </div>
                  <div class="form-field">
                    <input type="password" placeholder="비밀번호" v-model="user.passwd" @keyup.enter="doLogin"/>
                  </div>
                </div>
                <div class="error-message">
                  {{ erm }}
                </div>
                <div class="login-form-button">
                  <loading-login-comp v-if="clickedLogin"></loading-login-comp>
                  <button class="login" @click="doLogin"
                          v-if="!clickedLogin"><i class="fa fa-sign-in"></i> 로그인
                  </button>
                  <button class="login" @click="showRegisterPanel"
                          v-if="!clickedLogin"><i class="fa fa-plus-circle"></i> 회원가입
                  </button>
                  <button class="not_login" @click="showNotMemberCustomerPanel"
                          v-if="!clickedLogin"><i class="fa fa-question-circle-o"></i> 비회원문의
                  </button>

                </div>
              </div>
              <div class="login-left maintaining" v-if="$store.state.isMaintaining"
                   v-html="$store.state.maintainingMessage">

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../store/mutation-types";
import {loginRegisterMixin} from "../common/mixin";
import {getUserInfo, login} from "../network/userRequest";
import LoadingLoginComp from "./LoadingLoginComp";

export default {
  name: "LoginComp",
  components: {LoadingLoginComp},
  mixins: [loginRegisterMixin],
  data() {
    return {
      user: {
        username: '',
        passwd: '',
      },
      isShowMessage: false,
      content: '',
      erm: '',
      telMessage: null,
      clickedLogin: false
    }
  },
  methods: {
    showRegisterPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
      }
    },
    closePanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false
    },
    doLogin() {
      this.clickedLogin = true
      // if (!this.testRegxUserName) {
      //     this.$swal({
      //         title: '아이디는 4-15자리 영문,숫자조합입니다',
      //         type: 'error',
      //         showCancelButton: false,
      //         showConfirmButton: true
      //     })
      //     return false;
      // }
      // if (!this.testRegxPasswd) {
      //     this.$swal({
      //         title: '비밀번호는 4-20자리 입니다',
      //         type: 'error',
      //         showCancelButton: false,
      //         showConfirmButton: true
      //     })
      //     return false;
      // }
      login(this.user).then(res => {
        if (res.data.success) {
          getUserInfo().then(res => {
            this.$store.state.isLogin = true;
            this.$store.state.isShowLoginPanel = false
            this.$store.state.isShowRegisterPanel = false
            const userInfo = res.data.data
            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            window.sessionStorage.setItem("isLogin", 'true');
          }, err => {
            console.log(err)
          })
        } else {
          this.erm = res.data.msg
        }
        this.clickedLogin = false;
      })
    },
    showNotMemberCustomerPanel() {
      this.$bus.$emit('showMobileTopMenu', false)
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = false
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowNotMemberCustomerePanel = true
      }
    },

  },
  created() {
  },
  computed: {
    allowUsername() {
      return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
    },
    allowPasswd() {
      return /^.{3,30}$/.test(this.passwd)
    }
  }
}
</script>

<style scoped>

@media screen and (max-width: 1024px) {
  .login-warp {
    width: 370px !important;
    height: 480px !important;
    background-color: #fff9ff;
  }

  .login-warp .login-cont .login-d .login-left {
    width: 100% !important;
  }

  .login-warp .login-cont .login-d .login-right {
    display: none !important;
  }
}

.showLoginPannel {
  transform: translateY(0%);
}

.login-warp {
  width: 400px;
  height: 520px;
  background-color: var(--loginWarpBg);
  color: #fff9ff;
}

.login-warp .login-head {
  background-color: var(--loginWarpHeadBg);
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  z-index: 5002;
}

.login-warp .login-head h4 {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0;
  vertical-align: middle;
}

.login-panel-close {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 40px;
  padding: 2px;
  cursor: pointer;
}

.login-panel-close img {
  height: 30px;
}

.login-warp .login-cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 5001;
  margin-top: -40px;
}

.login-warp .login-cont .login-d {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 40px;
}

.login-warp .login-cont .login-d .login-left {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5002;
  position: relative;
}

.error-message {
  text-align: center;
  font-size: 14px;
  color: #ff0000;
}

.login-warp .login-cont .login-d .login-left .login-logo {
  text-align: center;
  margin-top: 24px;
}

.login-warp .login-cont .login-d .login-left .login-logo img {
  height: 70px;
}

.login-warp .login-cont .login-d .login-left .login-form {
  width: 100%;
  padding: 0 16px 16px;
}

.login-warp .login-cont .login-d .login-left .login-form .form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  margin-top: 10px;
}

.login-warp .login-cont .login-d .login-left .login-form .form-field span {
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  font-size: 13px;
  color: #adadad;
}

.login-warp .login-cont .login-d .login-left .login-form .form-field input {
  background: #111111;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #fff9ff;
  font-size: 15px;
  height: 38px;
  line-height: 38px;
  outline: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.login-warp .login-cont .login-d .login-left .login-form-button {
  width: 100%;
  padding: 0 16px;

}

.login-warp .login-cont .login-d .login-left .login-form-button button {
  width: 100%;
  border-radius: 50px;
  text-align: center;
  height: 38px;
  border: 2px solid #bdbdbd;
  color: #e1dfe1;
  background: linear-gradient(180deg, #030303, #403e3f);
  margin: 5px;
}

.login-warp .login-cont .login-d .login-left .login {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-weight: bold;
  background-color: #c00000;
  color: #fff9ff;
}

.login-warp .login-cont .login-d .login-left .not_login {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  height: 36px;
  line-height: 36px;
  margin-top: 20px;
  background-color: #d1423f;
}

.login-warp .login-cont .login-d .login-left .register {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #ffffff;
  color: #ffffff;
  margin-top: 10px;
}

.login-warp .login-cont .login-d .login-left .tel-message {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffa500;
  padding: 10px 0;
}

.login-warp .login-cont .login-d .login-left .login-bottom {
  width: 100%;
  background-color: var(--loginWarpHeadBg);
  position: absolute;
  bottom: 0;
  padding: 16px;
}

.login-warp .login-cont .login-d .login-left .login-bottom button {
  width: 100% !important;
}

.login-warp .login-cont .login-d .login-left .login-bottom .join-text {
  color: #fff9ff;
  font-size: 12px;
}

/*login panel right*/
.login-warp .login-cont .login-d .login-right {
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 5002;
}

.maintaining {
  text-align: center;
  box-sizing: border-box;
  padding-top: 30px;
}

</style>