import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Login = () => import('../views/user/Login');
const Register = () => import('../views/user/Register');
const Main = () => import('../views/user/Main');
const SportsOriginal = () => import('../views/sports/SportsOriginal');
const SportsOriginalSpecial = () => import('../views/sports/SportsOriginalSpecial');
const SportsGameResult = () => import('../views/sports/SportsGameResult');
const SportsBetInfo = () => import('../views/sports/SportsBetInfo');
const UserInfo = () => import('../views/user/UserInfo');
const CustomerCenter = () => import('../views/user/CustomerCenter');
const Board = () => import('../views/user/Board');
const Notice = () => import('../views/user/Notice');
const Event = () => import('../views/user/Event');
const BetRule = () => import('../views/user/BetRule');
const BoardDetail = () => import('../views/user/BoardDetail');
const CustomerCenterDetail = () => import('../views/user/CustomerCenterDetail');
const EventDetail = () => import('../views/user/EventDetail');
const CulChek = () => import('../views/user/CulCheck');
const Rullet = () => import('../views/user/Rullet');
const Coupon = () => import('../views/user/Coupon');
const LiveTV = () => import('../views/user/LiveTV');
const Recharge = () => import('../views/user/Recharge');
const Exchange = () => import('../views/user/Exchange');
const Message = () => import('../views/user/Message');
const LeisureGame = () => import('../views/leisuregame/LeisureGame');
const LeisureGameBetInfo = () => import('../views/leisuregame/LeisrueGameBetInfo');
const LeisureGameResult = () => import('../views/leisuregame/LeisureGameResult');
const CasinoSlotGameHonor = () => import('../views/casino/CasinoSlotGameHonor');
const SportsInplay = () => import('../views/inplay/SportsInplay');

/*administrator*/

/*관리자*/
const ManagerLogin = () => import('../views/administrator/ManagerLogin');
const MainStatistic = () => import('../views/administrator/MainStatistic');
const ManagerSportsGame = () => import('../views/administrator/ManagerSportsGame');
const ManagerLeisureGame = () => import('../views/administrator/ManagerLeisureGame');
const ManagerUser = () => import('../views/administrator/ManagerUser');
const ManagerSportsBet = () => import('../views/administrator/ManagerSportsBet');
const ManagerLeisureGameBet = () => import('../views/administrator/ManagerLeisureGameBet');
const ManagerCasinoBetHonor = () => import('../views/administrator/ManagerCasinoBetHonor');
const ManagerCasinoBetXimax = () => import('../views/administrator/ManagerCasinoBetXimax');
const ManagerTgame365Bet = () => import('../views/administrator/ManagerTgame365Bet');
const ManagerApplyRecharge = () => import('../views/administrator/ManagerApplyRecharge');
const ManagerApplyExchange = () => import('../views/administrator/ManagerApplyExchange');
const ManagerUserGroup = () => import('../views/administrator/ManagerUserGroup');
const ManagerRank = () => import('../views/administrator/ManagerRank');
const ManagerPartner = () => import('../views/administrator/ManagerPartner');
const ManagerRegisterCode = () => import('../views/administrator/ManagerRegisterCode');
const ManagerCustomerCenter = () => import('../views/administrator/ManagerCustomerCenter');
const ManagerLeisureConfig = () => import('../views/administrator/ManagerLeisureConfig');
const ManagerMessage = () => import('../views/administrator/ManagerMessage');
const ManagerBoard = () => import('../views/administrator/ManagerBoard');
const ManagerCashPointLog = () => import('../views/administrator/ManagerCashPointLog');
const ManagerModifiedHistory = () => import('../views/administrator/ManagerModifiedHistory');
const ManagerLoginHistory = () => import('../views/administrator/ManagerLoginHistory');
const ManagerGameType = () => import('../views/administrator/ManagerGameType');
const ManageLeague = () => import('../views/administrator/ManageLeague');
const ManagerTeamNameMatching = () => import('../views/administrator/ManagerTeamNameMatching');
const ManagerSiteConfig = () => import('../views/administrator/ManagerSiteConfig');
const ManagerCrossRule = () => import('../views/administrator/ManagerCrossRule');
const ManagerCustomerTemplate = () => import('../views/administrator/ManagerCustomerTemplate');
const ManagerIP = () => import('../views/administrator/ManagerIP');
const ManagerPopup = () => import('../views/administrator/ManagerPopup');
const ManagerChangePasswd = () => import('../views/administrator/ManagerChangePasswd');
const Administrator = () => import('../views/administrator/Administrator');

const routes = [
    /*관리자*/
    {
        path: '/manager',
        name: 'manager',
        component: ManagerLogin,
        meta: {
            title: '관리자 - 로그인'
        }
    },
    {
        path: '/administrator',
        name: 'administrator',
        component: Administrator,
        children: [
            {
                path: 'manager_popup',
                name: 'manager_popup',
                component:ManagerPopup
                ,
                meta: {
                    title: '관리자-팝업 설정'
                }
            },
            {
                path: 'manager_ipcontainer',
                name: 'manager_ipcontainer',
                component: ManagerIP,
                meta: {
                    title: '관리자-IP 설정'
                }
            },
            {
                path: 'manager_custemp',
                name: 'manager_custemp',
                component: ManagerCustomerTemplate,
                meta: {
                    title: '관리자-템플렛 설정'
                }
            },
            {
                path: 'manager_crossrule',
                name: 'manager_crossrule',
                component: ManagerCrossRule,
                meta: {
                    title: '관리자-크로스 룰 설정'
                }
            },
            {
                path: 'manager_siteconfig',
                name: 'manager_siteconfig',
                component: ManagerSiteConfig,
                meta: {
                    title: '관리자-사이트설정'
                }
            },
            {
                path: 'manager_teamnamemaching',
                name: 'manager_teamnamemaching',
                component: ManagerTeamNameMatching,
                meta: {
                    title: '관리자-팀명매칭'
                }
            },
            {
                path: 'manager_league',
                name: 'manager_league',
                component: ManageLeague,
                meta: {
                    title: '관리자-리그관리'
                }
            },
            {
                path: 'manager_gametype',
                name: 'manager_gametype',
                component: ManagerGameType,
                meta: {
                    title: '관리자-종목관리'
                }
            },
            {
                path: 'manager_loglogin',
                name: 'manager_loglogin',
                component: ManagerLoginHistory,
                meta: {
                    title: '관리자-로그인 로그관리'
                }
            },
            {
                path: 'manager_modifiedhistory',
                name: 'manager_modifiedhistory',
                component: ManagerModifiedHistory,
                meta: {
                    title: '관리자-정보변경 로그관리'
                }
            },
            {
                path: 'manager_cashpointlog1',
                name: 'manager_cashpointlog1',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리'
                }
            },
            {
                path: 'manager_cashpointlog2',
                name: 'manager_cashpointlog2',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리'
                }
            },
            {
                path: 'manager_board1',
                name: 'manager_board1',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리'
                }
            },
            {
                path: 'manager_board2',
                name: 'manager_board2',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리'
                }
            },
            {
                path: 'manager_board3',
                name: 'manager_board3',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리'
                }
            },
            {
                path: 'manager_board4',
                name: 'manager_board4',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리'
                }
            },
            {
                path: 'manager_board5',
                name: 'manager_board5',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리'
                }
            },
            {
                path: 'manager_message',
                name: 'manager_message',
                component: ManagerMessage,
                meta: {
                    title: '관리자-쪽지 관리'
                }
            },
            {
                path: 'manager_leisure_config',
                name: 'manager_leisure_config',
                component: ManagerLeisureConfig,
                meta: {
                    title: '관리자-미니게임설정 관리'
                }
            },
            {
                path: 'manager_customer',
                name: 'manager_customer',
                component: ManagerCustomerCenter,
                meta: {
                    title: '관리자-고객센터 관리'
                }
            },
            {
                path: 'manager_register_code',
                name: 'manager_register_code',
                component: ManagerRegisterCode,
                meta: {
                    title: '관리자-가입코드 관리'
                }
            },
            {
                path: 'manager_partner',
                name: 'manager_partner',
                component: ManagerPartner,
                meta: {
                    title: '관리자-파트너 관리'
                }
            },
            {
                path: 'manager_rank',
                name: 'manager_rank',
                component: ManagerRank,
                meta: {
                    title: '관리자-레벨관리'
                }
            },
            {
                path: 'manager_usergoup',
                name: 'manager_usergoup',
                component: ManagerUserGroup,
                meta: {
                    title: '관리자-그룹관리'
                }
            },
            {
                path: 'manager_exchange',
                name: 'manager_exchange',
                component: ManagerApplyExchange,
                meta: {
                    title: '관리자-출금관리'
                }
            },
            {
                path: 'manager_tgame365_bet',
                name: 'manager_tgame365_bet',
                component: ManagerTgame365Bet,
                meta: {
                    title: '관리자-토큰게임 베팅내역'
                }
            },
            {
                path: 'manager_casino_honor',
                name: 'manager_casino_honor',
                component: ManagerCasinoBetHonor,
                meta: {
                    title: '관리자-Honor카지노베팅내역'
                }
            },
            {
                path: 'manager_casino_ximax',
                name: 'manager_casino_ximax',
                component: ManagerCasinoBetXimax,
                meta: {
                    title: '관리자-Ximax카지노베팅내역'
                }
            }
            ,
            {
                path: 'manager_leisuregamebet',
                name: 'manager_leisuregamebet',
                component: ManagerLeisureGameBet,
                meta: {
                    title: '관리자-미니게임 베팅'
                }
            },
            {
                path: 'manager_sportsbet',
                name: 'manager_sportsbet',
                component: ManagerSportsBet,
                meta: {
                    title: '관리자-스포츠 베팅'
                }
            },
            {
                path: 'manager_user',
                name: 'manager_user',
                component: ManagerUser,
                meta: {
                    title: '관리자-회원정보관리'
                }
            },
            {
                path: 'manager_leisure_game1',
                name: 'manager_leisure_game1',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game2',
                name: 'manager_leisure_game2',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game3',
                name: 'manager_leisure_game3',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game4',
                name: 'manager_leisure_game4',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game5',
                name: 'manager_leisure_game5',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game6',
                name: 'manager_leisure_game6',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game7',
                name: 'manager_leisure_game7',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game8',
                name: 'manager_leisure_game8',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game9',
                name: 'manager_leisure_game9',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game10',
                name: 'manager_leisure_game10',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game11',
                name: 'manager_leisure_game11',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game12',
                name: 'manager_leisure_game12',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_leisure_game13',
                name: 'manager_leisure_game13',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임'
                }
            },
            {
                path: 'manager_sports',
                name: 'manager_sports',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_1',
                name: 'manager_sports_1',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_2',
                name: 'manager_sports_2',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_3',
                name: 'manager_sports_3',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_4',
                name: 'manager_sports_4',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_5',
                name: 'manager_sports_5',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_6',
                name: 'manager_sports_6',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_7',
                name: 'manager_sports_7',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'manager_sports_8',
                name: 'manager_sports_8',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기'
                }
            },
            {
                path: 'main_statistic',
                name: 'main_statistic',
                component: MainStatistic,
                //component: resolve => require(['../views/administrator/MainStatistic'], resolve),
                meta: {
                    title: '관리자-통계표'
                }
            },
            {
                path: 'manager_recharge',
                name: 'manager_recharge',
                component: ManagerApplyRecharge,
                meta: {
                    title: '관리자-입금관리'
                }
            },
            {
                path: 'manager_changepasswd',
                name: 'manager_changepasswd',
                component: ManagerChangePasswd,
                meta: {
                    title: '관리자-비밀번호 변경'
                }
            },
            {
                path: '/',
                redirect: 'main_statistic'
            },

        ]
    },
    {
        path: '/',
        redirect: '/main'
    },
    {
        path: '/login-back',
        name: 'login-back',
        component: Login,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/register-back',
        name: 'register-back',
        component: Register,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            title: '메인'
        }
    },
    {
        path: '/sports',
        name: 'sports',
        component: SportsOriginal,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_special',
        name: 'sports_special',
        component: SportsOriginalSpecial,
        meta: {
            title: '스포츠-스페셜'
        }
    },
    {
        path: '/sports_inplay',
        name: 'sports_inplay',
        component: SportsInplay,
        meta: {
            title: 'Inplay'
        }
    },
    {
        path: '/sports_result',
        name: 'sports_result',
        component: SportsGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/leisure_result',
        name: 'leisure_result',
        component: LeisureGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/sbetinfo',
        name: 'sbetinfo',
        component: SportsBetInfo,
        meta: {
            title: '베팅내역(Sports)'
        }
    },
    {
        path: '/leisure_betinfo',
        name: 'leisure_betinfo',
        component: LeisureGameBetInfo,
        meta: {
            title: '베팅내역(Mini)'
        }
    },
    {
        path: '/customer_center',
        name: 'customer_center',
        component: CustomerCenter,
        meta: {
            title: '1:1문의'
        }
    },
    {
        path: '/customer_center_detail/:id',
        name: 'customerCenterDetail',
        component: CustomerCenterDetail,
        meta: {
            title: '고객센터 상세보기'
        }
    },
    {
        path: '/board',
        name: 'board',
        component: Board,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/board_detail/:id',
        name: 'boardDetail',
        component: BoardDetail,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: '공지사항'
        }
    },
    {
        path: '/betrule',
        name: 'betrule',
        component: BetRule,
        meta: {
            title: '베팅규정'
        }
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/event_detail/:id',
        name: 'eventDetail',
        component: EventDetail,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/recharge',
        name: 'recharege',
        component: Recharge,
        meta: {
            title: '충전'
        }
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: Exchange,
        meta: {
            title: '환전'
        }
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '쪽지'
        }
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: UserInfo,
        meta: {
            title: '내정보'
        }
    }
    ,
    {
        path: '/casino',
        name: 'CasinoSlotGameHonor',
        component: CasinoSlotGameHonor,
        meta: {
            title: '카지노&슬롯'
        }
    },
    {
        path: '/livetv',
        name: 'livetv',
        component: LiveTV,
        meta: {
            title: 'Live TV'
        }
    },
    {
        path: '/culcheck',
        name: 'culcheck',
        component: CulChek,
        meta: {
            title: '출석체크'
        }
    },
    {
        path: '/rullet',
        name: 'rullet',
        component: Rullet,
        meta: {
            title: '룰렛'
        }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: Coupon,
        meta: {
            title: '쿠폰'
        }
    },
    {
        path: '/leisure',
        name: 'leisure',
        component: LeisureGame,
        children: [
            {
                path: '/',
                redirect: 'bet365_premiership'
            },
            {
                path: 'npowersadali3s',
                name: 'leisure_detail_npowersadali3s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerSadali3M'], resolve),
                meta: {
                    title: 'Named 파워사다리 3분'
                }
            },
            {
                path: 'npowersadali5s',
                name: 'leisure_detail_npowersadali5s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerSadali5M'], resolve),
                meta: {
                    title: 'Named 파워사다리 5분'
                }
            },
            {
                path: 'npowerball3s',
                name: 'leisure_detail_npowerball3s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerBall3M'], resolve),
                meta: {
                    title: 'Named 파워볼 3분'
                }
            },
            {
                path: 'npowerball5s',
                name: 'leisure_detail_npowerball5s',
                component: resolve => require(['../views/leisuregame/children/NamedNewPowerBall5M'], resolve),
                meta: {
                    title: 'Named 파워볼 5분'
                }
            } ,
            {
                path: 'powersadali',
                name: 'leisure_detail_powersadali',
                component: resolve => require(['../views/leisuregame/children/PowerSadali'], resolve),
                meta: {
                    title: '파워사다리'
                }
            },
            {
                path: 'speedkino',
                name: 'leisure_detail_speedkino',
                component: resolve => require(['../views/leisuregame/children/SpeedKino'], resolve),
                meta: {
                    title: '스피드키노'
                }
            },
            {
                path: 'kinosadali',
                name: 'leisure_detail_kinosadali',
                component: resolve => require(['../views/leisuregame/children/KinoSadali'], resolve),
                meta: {
                    title: '키노 사다리'
                }
            },
            {
                path: 'ssd1',
                name: 'leisure_detail_ssd1',
                component: resolve => require(['../views/leisuregame/children/StarSadali1'], resolve),
                meta: {
                    title: '별다리 1분'
                }
            },
            {
                path: 'ssd2',
                name: 'leisure_detail_ssd2',
                component: resolve => require(['../views/leisuregame/children/StarSadali2'], resolve),
                meta: {
                    title: '별다리 2분'
                }
            },
            {
                path: 'ssd3',
                name: 'leisure_detail_ssd3',
                component: resolve => require(['../views/leisuregame/children/StarSadali3'], resolve),
                meta: {
                    title: '별다리 3분'
                }
            },
            {
                path: 'nameddalidali',
                name: 'leisure_detail_nameddalidali',
                component: resolve => require(['../views/leisuregame/children/NamedDalidali'], resolve),
                meta: {
                    title: '네임드 다리다리'
                }
            },
            {
                path: 'namedsadali',
                name: 'leisure_detail_namedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSadali'], resolve),
                meta: {
                    title: '네임드 사다리'
                }
            },
            {
                path: 'namedspeedsadali',
                name: 'leisure_detail_namedspeedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSpeedSadali'], resolve),
                meta: {
                    title: '네임드 스피드 사다리'
                }
            },
            {
                path: 'namedsnail',
                name: 'leisure_detail_namedsnail',
                component: resolve => require(['../views/leisuregame/children/NamedSnail'], resolve),
                meta: {
                    title: '네임드 달팽이'
                }
            },
            {
                path: 'fx1m',
                name: 'leisure_detail_fx1m',
                component: resolve => require(['../views/leisuregame/children/Fx1m'], resolve),
                meta: {
                    title: 'FX 1M'
                }
            },
            {
                path: 'fx2m',
                name: 'leisure_detail_fx2m',
                component: resolve => require(['../views/leisuregame/children/Fx2m'], resolve),
                meta: {
                    title: 'FX 2M'
                }
            },
            {
                path: 'fx3m',
                name: 'leisure_detail_fx3m',
                component: resolve => require(['../views/leisuregame/children/Fx3m'], resolve),
                meta: {
                    title: 'FX 3M'
                }
            },
            {
                path: 'fx4m',
                name: 'leisure_detail_fx4m',
                component: resolve => require(['../views/leisuregame/children/Fx4m'], resolve),
                meta: {
                    title: 'FX 4M'
                }
            },
            {
                path: 'fx5m',
                name: 'leisure_detail_fx5m',
                component: resolve => require(['../views/leisuregame/children/Fx5m'], resolve),
                meta: {
                    title: 'FX 5M'
                }
            },
            {
                //Bet365SoccerPremiership
                path: 'bet365_premiership',
                name: 'leisure_detail_bet365_premiership',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerPremiership'], resolve),
                meta: {
                    title: 'Bet365 프리미어'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_superleague',
                name: 'leisure_detail_bet365_superleague',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerSuperLeague'], resolve),
                meta: {
                    title: 'Bet365 슈퍼리그'
                }
            },
            {
                //Bet365SoccerEuroCup
                path: 'bet365_eurocup',
                name: 'leisure_detail_bet365_eurocup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerEuroCup'], resolve),
                meta: {
                    title: 'Bet365 유로컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_worldcup',
                name: 'leisure_detail_bet365_worldcup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerWorldCup'], resolve),
                meta: {
                    title: 'Bet365 월드컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_goldenhill',
                name: 'leisure_detail_bet365_goldenhill',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceGoldenHill'], resolve),
                meta: {
                    title: 'Bet365 개경주 골든힐'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_hillside',
                name: 'leisure_detail_bet365_hillside',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceHillSide'], resolve),
                meta: {
                    title: 'Bet365 개경주 힐사이드'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_horserace',
                name: 'leisure_detail_horserace',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace'], resolve),
                meta: {
                    title: '경마 트렌담 파크'
                }
            },
            {
                //
                path: 'bet365_horserace2',
                name: 'leisure_detail_horserace2',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace2'], resolve),
                meta: {
                    title: '경마 브리타니아 웨이'
                }
            },
            {
                path: 'bet365_horserace3',
                name: 'leisure_detail_horserace3',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace3'], resolve),
                meta: {
                    title: '경마 페스티벌 다운즈'
                }
            },
            {
                path: 'bet365_horserace4',
                name: 'leisure_detail_horserace4',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace4'], resolve),
                meta: {
                    title: '경마 빅토리아 파크'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_water',
                name: 'leisure_detail_bet365_warter',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallWaterLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_baker',
                name: 'leisure_detail_bet365_baker',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallBakerLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball',
                name: 'leisure_detail_powerball',
                component: resolve => require(['../views/leisuregame/children/Powerball'], resolve),
                meta: {
                    title: '파워볼'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball_lotto',
                name: 'leisure_detail_powerball_lotto',
                component: resolve => require(['../views/leisuregame/children/PowerballLotto'], resolve),
                meta: {
                    title: '파워볼 로또'
                }
            }
            ,
            {
                //크라운 섯다
                path: 'sutda',
                name: 'leisure_detail_sutda',
                component: resolve => require(['../views/leisuregame/children/CrownSutda'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //크라운 나인볼
                path: 'nineball',
                name: 'leisure_detail_nineball',
                component: resolve => require(['../views/leisuregame/children/CrownNineBall'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            // {
            //     //로투스 바카라
            //     path: 'lotus_bakara',
            //     name: 'leisure_detail_lotus_bakara',
            //     component: resolve => require(['../views/leisuregame/children/LotusBakara'], resolve),
            //     meta: {
            //         title: '로투스 바카라'
            //     }
            // },
            // {
            //     //로투스 바카라
            //     path: 'lotus_oddeven',
            //     name: 'leisure_detail_lotus_oddeven',
            //     component: resolve => require(['../views/leisuregame/children/LotusOddEven'], resolve),
            //     meta: {
            //         title: '로투스 홀짝'
            //     }
            // },
            // {
            //     //로투스 바카라
            //     path: 'lotus_dragontiger',
            //     name: 'leisure_detail_lotus_dragontiger',
            //     component: resolve => require(['../views/leisuregame/children/LotusDragonTiger'], resolve),
            //     meta: {
            //         title: '로투스 드래곤타이거'
            //     }
            // },
            {
                //sky speed 바카라
                path: 'sky_speed_bakara',
                name: 'leisure_detail_sky_speed_bakara',
                component: resolve => require(['../views/leisuregame/children/SkySpeedBakara'], resolve),
                meta: {
                    title: '스카이파크 Speed 바카라'
                }
            },
            {
                //sky speed 바카라
                path: 'sky_speed_bakara2',
                name: 'leisure_detail_sky_speed_bakara2',
                component: resolve => require(['../views/leisuregame/children/SkySpeedBakara2'], resolve),
                meta: {
                    title: '스카이파크 Speed 바카라2'
                }
            },
            {
                //sky 홀짝
                path: 'sky_oddeven',
                name: 'leisure_detail_sky_oddeven',
                component: resolve => require(['../views/leisuregame/children/SkyOddEven'], resolve),
                meta: {
                    title: '스카이파크 홀짝'
                }
            },
            {
                //sky 주사위
                path: 'sky_dice',
                name: 'leisure_detail_sky_dice',
                component: resolve => require(['../views/leisuregame/children/SkyDice'], resolve),
                meta: {
                    title: '스카이파크 주사위'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_bakara',
                name: 'leisure_detail_mgm_bakara',
                component: resolve => require(['../views/leisuregame/children/MgmBakara'], resolve),
                meta: {
                    title: 'mgm 바카라'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_oddeven',
                name: 'leisure_detail_mgm_oddeven',
                component: resolve => require(['../views/leisuregame/children/MgmOddEven'], resolve),
                meta: {
                    title: 'mgm 홀짝'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball1m',
                name: 'leisure_detail_eospowerball1m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall1M'], resolve),
                meta: {
                    title: 'EOS 파워볼 1분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball3m',
                name: 'leisure_detail_eospowerball3m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall3M'], resolve),
                meta: {
                    title: 'EOS 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball5m',
                name: 'leisure_detail_eospowerball5m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall5M'], resolve),
                meta: {
                    title: 'EOS 파워볼 5분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball3m',
                name: 'leisure_detail_hspowerball3m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall3M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball5m',
                name: 'leisure_detail_hspowerball5m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall5M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 5분'
                }
            },
            {
                path: 'bgbgpowerball',
                name: 'leisure_detail_bgbgpowerball',
                component: resolve => require(['../views/leisuregame/children/BGBGPowerBall'], resolve),
                meta: {
                    title: '보글보글 파워볼'
                }
            },
            {
                path: 'bgbgsadali',
                name: 'leisure_detail_bgbgsadali',
                component: resolve => require(['../views/leisuregame/children/BGBGSadali'], resolve),
                meta: {
                    title: '보글보글 사다리'
                }
            },
            {
                path: 'mablssadali2m',
                name: 'leisure_detail_mablssadali2m',
                component: resolve => require(['../views/leisuregame/children/MablsSadali2M'], resolve),
                meta: {
                    title: '슈퍼맨 사다리 2분'
                }
            },
            {
                path: 'mablsjoker',
                name: 'leisure_detail_mablsjoker',
                component: resolve => require(['../views/leisuregame/children/MablsJoker'], resolve),
                meta: {
                    title: '할리퀸&조커'
                }
            }
            
            


        ],
        meta: {
            title: '미니게임'
        }
    },
]

const router = new VueRouter({
    /* mode: 'history',*/
    routes,
    linkExactActiveClass: 'acvice_menu',
})

/*method*/
const contains = function (needle, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === needle) return true;
    }
    return false;
}
router.beforeEach((to, from, next) => {
    // let names = ['main', 'sports', 'sports_inplay']
    // if (contains(to.name, names)) {
    //     next()
    // } else {
    //     let isLogin = router.app.$store.state.isLogin;
    //     if (!isLogin) {
    //         router.app.$store.state.isShowLoginPanel = true;
    //         next({name: 'main', query: {t: Date.now()}})
    //     } else {
    //         next()
    //     }
    // }
    next()

});
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
