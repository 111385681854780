import swal from 'sweetalert2'

export default {
  install: (Vue) => {
    // sweetalert2 的设置默认配置的方法
    swal.setDefaults({
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(79,94,253)',
      cancelButtonColor: 'rgb(255,23,23)',
      confirmButtonText:'확인',
      cancelButtonText:'취소',
      background: '#020202',
    })

    // 添加全局方法
    Vue.swal = swal
    // 添加实例方法
    Vue.prototype.$swal = swal
  }
}