<template>
  <!--오른쪽 메뉴-->
  <div class="user_box">
    <div v-if="$store.state.isLogin">
      <div class="login_head">
        <div style="font-weight: bold;margin-left: 2px;width: 100%;font-size: 16px">


        </div>
      </div>
      <div class="member_info">
        <div class="mi">
           <div class="icon">
               <img :src="$store.state.userInfo.rank.imageUrl" width="18">
                <span style="margin-left: 3px">{{$store.state.userInfo.rank.rankName}}</span>
           </div>
          <div class="nick">
            <span>{{$store.state.userInfo.nickname}}</span>
            <button class="btn-loch ml-5" @click="logout">
              <img class="iconimg" src="../assets/images/icon/common/gamen/signout.png"
                   style="width: 15px;vertical-align: middle"> 로그아웃
            </button>
          </div>

        </div>

        <div class="mi" @click="initUserInfo" style="cursor: pointer">
          <!--                    <div class="icon">-->
          <!--                        <img class="iconimg" src="../assets/images/icon/common/gamen/icon_money.png">-->
          <!--                    </div>-->
          <div class="txt">
            보유머니
          </div>
          <div class="num">
            {{ $store.state.userInfo.cash|comma }}
          </div>
          <div class="txt" style="text-align: center">
            포인트
          </div>
          <div class="num">
            {{ $store.state.userInfo.userPoint|comma }}
          </div>
        </div>

        <div class="mi" @click="refreshCasinoCash()" style="cursor: pointer">
          <!--                    <div class="icon">-->
          <!--                        <img class="iconimg" src="../assets/images/icon/common/gamen/icon_casinomoney.png">-->
          <!--                    </div>-->
          <div class="txt">
            카지노
          </div>
          <div class="num" style="text-align: center">
            {{ $store.state.userInfo.casinocash|comma }}
          </div>
          <div class="txt" style="text-align: center">
            <router-link tag="span" to="/message">쪽지</router-link>
          </div>
          <router-link tag="div" to="/message" class="num" style="text-align: center">
            <span class="badge badge-gc">{{ $store.state.uncheckedMessageCount }}</span>
          </router-link>
        </div>

      </div>
    </div>
    <div v-if="!$store.state.isLogin && !$store.state.isMaintaining">
      <div class="login_bts">
        <button @click="showLoginPanel" class="login"><i class="fa fa-sign-in"></i> 로그인</button>
        <button @click="showRegisterPanel" class="reg"><i class="fa fa-plus-circle"></i> 회원가입</button>
        <button @click="showNotMemberCustomerPanel" class="cus"><i class="fa fa-question-circle-o"></i> 비회원문의
        </button>
      </div>
    </div>
  </div>


</template>

<script>
import {logout, changePoint, login, getUserInfo} from "../network/userRequest";
import {
  INIT_USERINFO,
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_USER_INFO
} from "../store/mutation-types";
import RightBarBannerComp from "./RightBarBannerComp";
import {loginRegisterMixin} from "../common/mixin";
import {getAccountBalance} from "../network/casinoHonorRequest";

export default {
  name: "UserInfoComp",
  mixins: [loginRegisterMixin],
  components: {RightBarBannerComp},
  data() {
    return {
      isShowSubordinate: false,
      user: {
        username: '',
        passwd: '',
      },
    }
  },
  methods: {
    subordinate() {
      this.isShowSubordinate = !this.isShowSubordinate;
    },
    showLoginPanel() {
      this.$bus.$emit('showMobileTopMenu', false)
      if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
        this.$store.state.isShowLoginPanel = true
        this.$store.state.isShowRegisterPanel = false
      }
    },
    showRegisterPanel() {
      this.$bus.$emit('showMobileTopMenu', false)
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowNotMemberCustomerePanel = false
      }
    },
    showNotMemberCustomerPanel() {
      this.$bus.$emit('showMobileTopMenu', false)
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = false
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowNotMemberCustomerePanel = true
      }
    },
    doLogin() {

      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          getUserInfo().then(res => {
            this.$store.state.isLogin = true;
            const userInfo = res.data.data
            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            window.sessionStorage.setItem("isLogin", 'true');
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.user.username = '';
            this.user.passwd = '';
            this.$router.push('/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    logout() {
      this.$bus.$emit('showMobileTopMenu', false)
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: '로그아웃',
      }).then((res) => {
        if (res.value) {

          logout();
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$router.replace("/main")
          this.$store.state.uncheckedMessageCount = 0;
          this.$store.state.isLogin = false;
        }
      })

    },
    initUserInfo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.$store.dispatch('actionUserInfo')
      setTimeout(() => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
      }, 1000)
    },
    initUncheckedMessage() {
      this.$store.dispatch('actionUnCheckedMessageCount')
    },
    changePoint() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.initUserInfo()
        }
      })
    },
    refreshCasinoCash() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    }
  },
  created() {

  }
}
</script>

<style scoped>
.user_box {
  width: 100%;
  --padding: 5px 5px 2px 5px;
  color: #fff9ff;
}



.user_box .btn-loch {
  background-color: var(--regBtnBg);
  border-radius: 5px;
  padding: 1px 2px;
  color: #fff9ff;
  font-size: 11px;
  line-height: 20px;

}


.user_box .login_head {
  background-color: var(--lefstItemTitleBg);
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff9ff;
  padding: 0 10px
}


.user_box .login_form table {
  width: 100%;
  background-color: transparent;
  border: 0 transparent;
}

.user_box .login_form input {
  width: 99%;
  text-align: left;
  padding-left: 5px;
  line-height: 22px;
  border: transparent;
  font-size: 12px;

}

.login_form td {
  border: 0;
  height: 25px;
  line-height: 25px;
}

.user_box .login_bts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #2c2c2c;
}
.user_box .login_bts button{
  border-radius: 50px;
  text-align: center;
  height: 38px;
  border: 2px solid #bdbdbd;
  color: #e1dfe1;
  background: linear-gradient(180deg, #030303, #403e3f);
  margin: 5px;
}

.user_box .login_bts .login {
  width: 75%;
}

.user_box .login_bts .reg {
  width: 75%;
}

.user_box .login_bts .cus {
  width: 75%;
}


.user_box .member_info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px;
  margin: 10px 0;
}

.user_box .member_info .mi {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #383838;
  background-color: #282828;
  border-radius: 5px;
  padding: 0 5px;
}

.user_box .member_info .mi .icon {
  width: 25%;
  text-align: left;
  font-size: 11px;
  display: flex;
  align-items: center;
}
.user_box .member_info .mi .nick {
  width: 75%;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user_box .member_info .mi .iconimg {
  width: 30px;
}

.user_box .member_info .mi .txt {
  width: 20%;
  text-align: left;
  color: #fff9ff;
}

.user_box .member_info .mi .num {
  width: 30%;
  text-align: center;
  color: #ffa604;
}

@media screen and (max-width: 1024px) {
  .user_box {
    --display: none !important;
    padding-top: 0px !important;
  }

  .right_login {
    margin-top: 10px !important;
  }

  .right_login input {
    height: 38px !important;
    line-height: 38px !important;
  }

  .right_login .button_panel button {
    line-height: 38px !important;
  }

}


</style>