let baseUrl = '';
let webSokcetUrl = '';

function getProductionUrl() {
  let prot = window.location.protocol
  let domain = window.location.host
  return prot + '//' + domain
}
function getSocketProt(){
  let prot = window.location.protocol
  let soketProto = 'wss:';
  if(prot == 'https:'){
    soketProto = 'wss:'
  }
  return  soketProto
}

function getProductWebsocketUrl() {
  let domainOrigin = window.location.host
  return getSocketProt()+'//' + domainOrigin + '/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
}

switch (process.env.NODE_ENV) {
  case 'development':
    baseUrl = 'http://localhost:7070/api/'
    webSokcetUrl = getSocketProt()+'//ch-2022.com/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
    break
  case 'production':
    baseUrl = getProductionUrl() + '/api/'
    webSokcetUrl = getProductWebsocketUrl()
    break
}

export const BASE_URL = baseUrl
export const WEBSOCKET_URL = webSokcetUrl
export const TIMEOUT = 20000