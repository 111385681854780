<template>
  <div class="menu-items">
    <div class="items">
      <router-link tag="div" to="/recharge" class="item">
        <img src="../assets/images/icon/mobile/menu/m3.png" alt="">입금
      </router-link>
      <router-link tag="div" to="/exchange" class="item">
        <img src="../assets/images/icon/mobile/menu/m4.png" alt="">출금
      </router-link>
      <div @click="changePoint" class="item">
        <img src="../assets/images/icon/mobile/menu/m11.png" alt="">포인트전환
      </div>
      <router-link tag="div" to="/sbetinfo" class="item">
        <img src="../assets/images/icon/mobile/menu/m5.png" alt="">베팅내역
      </router-link>
      <router-link tag="div" to="/customer_center" class="item">
        <img src="../assets/images/icon/mobile/menu/m10.png" alt="">고객센터
      </router-link>
    </div>
    <div class="items">
      <div @click="showSportsPanel" class="item">
        <img src="../assets/images/icon/mobile/menu/m7.png" alt="">스포츠
      </div>
      <router-link tag="div" to="/leisure/eospowerball1m" class="item">
        <img src="../assets/images/icon/mobile/menu/m2.png" alt="">미니게임
      </router-link>
      <router-link tag="div" to="/casino" class="item">
        <img src="../assets/images/icon/mobile/menu/m1.png" alt="">카지노&슬롯
      </router-link>
    </div>

    <div class="items">
      <router-link tag="div" to="/culcheck" class="item">
        <img src="../assets/images/icon/mobile/menu/m9.png" alt="">출석부
      </router-link>
      <router-link tag="div" to="/event" class="item">
        <img src="../assets/images/icon/mobile/menu/m6.png" alt="">이벤트
      </router-link>
      <router-link tag="div" to="/notice" class="item">
        <img src="../assets/images/icon/mobile/menu/m8.png" alt="">공지사항
      </router-link>
    </div>

  </div>
</template>

<script>
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
import {changePoint} from "../network/userRequest";

export default {
  name: "MobileMenuItemsComp",
  methods: {
    closeMobileMenu() {
      this.$bus.$emit('showMobileTopMenu', false);
    },
    showSportsPanel() {
      this.closeMobileMenu()
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowLeisureSelectPanel = false;
      this.$store.state.isShowBetInfoSelectPanel = false;
      this.$store.state.isShowSportsSelectPanel = true
    },

    showLeisurePanel() {
      this.closeMobileMenu()
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowSportsSelectPanel = false;
      this.$store.state.isShowBetInfoSelectPanel = false;
      this.$store.state.isShowLeisureSelectPanel = true;
    },
    showBetInfoPanel() {
      this.closeMobileMenu()
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowLeisureSelectPanel = false;
      this.$store.state.isShowSportsSelectPanel = false;
      this.$store.state.isShowBetInfoSelectPanel = true;
    },
    changePoint() {
      this.closeMobileMenu()
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$store.dispatch('actionUserInfo')
        }
      })
    },
  }
}
</script>

<style scoped>

.menu-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  background-color: #111111;
  margin-bottom: 200px;
}
.menu-items .items{
  width: 100%;
  border-bottom: 1px solid #2c2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.menu-items .items .item{
  width: 258px;
  height: 48px;
  background-image: url("../assets/images/icon/mobile/menu/mobile_menu_item_bg.png");
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding: 20px 0 20px 30px;
  font-weight: bold;
  font-size: 14px;
  margin: 2px;
}
.menu-items .items .item img{
  margin-right: 5px;
}


</style>