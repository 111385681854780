<template>
    <div class="popup">
        <div class="popup_section" v-for="(item,index) in plist"
             :style="[item.styObject]"
             :key="index"
             v-show="item.isShow && $store.state.popups.indexOf(item.id) === -1">
            <div class="pfoot" style="">
                <div @click="close24(item)" style="text-align: left;cursor: pointer">
                    <i class="fa fa-square"></i> 오늘하루 그만보기
                </div>
                <div style="text-align: right;cursor: pointer">
                    <button class="btn-close" @click="close(item)">닫기</button>
                </div>
            </div>
            <div class="pop_box">
                <div class="event_img">
                    <img v-if="item.type === 2" :src="item.imageUrl" alt="" style="width: 100%;cursor: pointer" @click="goSite(item)">
                    <span v-else v-html="item.content"> </span>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {getPopupList} from "../network/userRequest";

    export default {
        name: "PopupComp",
        data() {
            return {
                plist: []
            }
        },
        methods: {
            close24(item) {
                this.$set(item, 'isShow', false)
                this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
            },
            close(item) {
                //this.$store.state.popups.push(item.id)
                this.$set(item, 'isShow', false)
            },
            goSite(item){
              if(item.link){
                  window.open(item.link,'_blank')
              }
            },
            getPopList() {

                getPopupList().then(res => {
                    if (res.data.success) {
                        this.plist = res.data.data
                        let i = 1;
                        let preLeft = 0;
                        let preWidth = 375;
                        this.plist.forEach(popup => {

                            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
                            this.$set(popup, 'isShow', cookiePopupStatus == null)

                            if(i % 4 === 0){
                                preLeft = 0;
                            }
                            preLeft = preLeft + preWidth + 10

                            popup.styObject = {
                                left: preLeft + 'px',
                                top: popup.top + 'px',
                                opacity: 1,
                                width: 375+'px',
                                zIndex:popup.zindex
                            }
                            i++;
                        })
                    }
                })
            }
        },
        created() {
            this.getPopList()
        }
    }
</script>

<style scoped>
    .popup_section {
        position: absolute;
        background-color: #2d3137;
        color: #fff9ff;
        font-weight: 600;
    }

    .popup_section h4 {
        line-height: 20px;
        height: 20px;
        width: 100%;
        font-size: 12px;
        padding: 20px;
        color: #cca4fd;
    }

    .popup_section .btn-close {
        padding: 8px 8px;
        background-color: #c61d1e;
    }

    .pop_box {
        box-sizing: border-box;
        --padding: 20px;

    }

    .pop_box h2 span:hover {
        color: #f19f09;
    }

    .popup_section .pfoot {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        line-height: 32px
    }
    .pfoot div{
        width: 45%;
        font-size: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    @media screen and (max-width: 1024px) {
        .popup_section {
            position: absolute!important;
            z-index: 9999999;
            left: 0 !important;
            top: 112px !important;
            width: 100% !important;
        }
        .popup_section .pfoot {
            line-height: 38px;
        }
    }
</style>