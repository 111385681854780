<template>
  <router-link tag="div" to="" class="overlay" :class="{'showSelectPannel':$store.state.isShowSportsSelectPanel}"
               @click.native="closePanel">
    <div class="overlay-warp">
      <div class="overlay-open">

        <div class="selectButtons">
          <router-link to="/sports" tag="div" @click.native="closePanel">
            <img src="../assets/images/main/balam/sportsselect01.jpg" alt="">
          </router-link>
          <router-link to="/sports_special" tag="div" @click.native="closePanel">
            <img src="../assets/images/main/balam/sportsselect02.jpg" alt="">
          </router-link>
          <router-link to="/sports_inplay" tag="div" @click.native="closePanel">
            <img src="../assets/images/main/balam/sportsselect03.jpg" alt="">
          </router-link>
        </div>

      </div>
    </div>

  </router-link>
</template>

<script>

export default {
  name: "SportsSelectComp",
  data() {
    return {}
  },
  methods: {
    closePanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowSportsSelectPanel = false;
      this.$store.state.isShowLeisureSelectPanel = false;
    },
  },
  created() {
  },
  computed: {}
}
</script>

<style scoped>

.selectButtons {
  width: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  box-sizing: border-box;
  padding: 5px;
}

.selectButtons div {
  box-sizing: border-box;
  padding: 5px;
}

.selectButtons img {
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
}


.showSelectPannel {
  transform: translateY(0%);
}

@media screen and (max-width: 1024px) {

  .overlay .overlay-warp .overlay-open {
    width: 98% !important;
  }

}

</style>