<template>
    <!--메인메뉴 패널-->
    <div>
        <div class="mobile-menu-wapper" :class="{'activeMobileMenu':isShowTopMenu}">
            <div class="close" @click="closeMobileMenu()">
                <i class="fa fa-close" aria-hidden="true"></i>
            </div>
            <div class="content">
                <user-info-comp></user-info-comp>
                <mobile-menu-items-comp></mobile-menu-items-comp>
            </div>
        </div>
    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";
    import MobileMenuItemsComp from "../MobileMenuItemsComp";
    import UserInfoComp from "../UserInfoComp";
    import RightButtonsComp from "../RightButtonsComp";
    import RightBarBannerComp from "../RightBarBannerComp";
    import RightBarBannerComp2 from "../RightBarBannerComp2";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {changePoint} from "../../network/userRequest";

    export default {
        name: "MobileTopMenu",
        components: {RightBarBannerComp2, RightBarBannerComp, RightButtonsComp, UserInfoComp, MobileMenuItemsComp},
        data() {
            return {
                sportsConst,
                leisureConst,
                isShowTopMenu: false,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE
            }
        },
        methods: {
            closeMobileMenu(disp) {
                this.isShowTopMenu = false
                if (disp) {
                    this.$bus.$emit('sportsDisplayChanged', disp);
                }
            },
            showSportsPanel() {
                this.closeMobileMenu()
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.$store.state.isShowLeisureSelectPanel = false;
                this.$store.state.isShowSportsSelectPanel = true
            },

            showLeisurePanel() {
                this.closeMobileMenu()
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.$store.state.isShowSportsSelectPanel = false;
                this.$store.state.isShowLeisureSelectPanel = true;
            },
            showBetInfoPanel() {
                this.closeMobileMenu()
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.$store.state.isShowLeisureSelectPanel = false;
                this.$store.state.isShowSportsSelectPanel = false;
                this.$store.state.isShowBetInfoSelectPanel = true
            },
            changePoint() {
                this.closeMobileMenu()
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$store.dispatch('actionUserInfo')
                    }
                })
            },
        },
        created() {
            // this.$bus.$on('mobileTopMenuActive', () => {
            //     this.isShowTopMenu = true;
            // })
            this.$bus.$on('showMobileTopMenu', (isShow) => {
                this.isShowTopMenu = isShow;
            })

        }
    }
</script>

<style scoped>

    /*모바일 메뉴*/
    .mobile-menu-wapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 300px !important;
        height: 100vh;
        background-color: #111111;
        z-index: 99999;
        overflow-y: scroll;
        transform: translateX(-100%);
        transition: 0.3s transform;
        box-sizing: border-box;
        padding: 0px 0px;
    }

    .mobile-menu-wapper .content {
        width: 100%;
    }

    .mobile-menu-wapper .content .l {
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }

    .mobile-menu-wapper .content .l img {
        height: 40px;
    }

    .logo img {
        width: 100%;
        height: 50px;
    }

    .mobile-menu-wapper .close {
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 5px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        background-color: #c51a1b;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .activeMobileMenu {
        transform: translateX(0%) !important;
    }

    .mobile-menu-wapper .menu img {
        width: 100%;
    }

    .mobile-menu-wapper .line_menus .menu .icon {

    }

    .mobile-menu-wapper .line_menus .menu .txt {
        margin-left: 10px;
    }
</style>