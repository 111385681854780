<template>
    <router-link tag="div" to="" class="overlay" :class="{'showSelectPannel':$store.state.isShowLeisureSelectPanel}"
                 @click.native="closePanel">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="selectButtons">
                    <router-link to="/leisure/eospowerball1m" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/eos.jpg" alt="">
                    </router-link>
<!--                    <router-link to="/leisure/mablssadali2m" tag="div" @click.native="closePanel">-->
<!--                        <img src="../assets/images/main/gamen/mbs.jpg" alt="">-->
<!--                    </router-link>-->
                    <router-link to="/leisure/powersadali" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/ntry.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure/ssd1" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/bscore.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure/sky_speed_bakara" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/sky.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure/sutda" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/crown.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure/npowerball3s" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/named.jpg" alt="">
                    </router-link>
                    <router-link to="/leisure/bet365_superleague" tag="div" @click.native="closePanel">
                        <img src="../assets/images/main/gamen/b365.png" alt="">
                    </router-link>
                  <router-link to="" tag="div">
                  </router-link>
                </div>

            </div>
        </div>

    </router-link>
</template>

<script>
    export default {
        name: "LeisureSelectComp",
        data() {
            return {}
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.$store.state.isShowSportsSelectPanel = false;
                this.$store.state.isShowLeisureSelectPanel = false;
                this.$store.state.isShowBetInfoSelectPanel = false;
            },
        },
        created() {
        },
        computed: {}
    }
</script>

<style scoped>


    .selectButtons {
        width: 950px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        --background: #262626;
        box-sizing: border-box;
        padding: 5px 0;
    }
    .selectButtons div{
        width: 24.9%;
        box-sizing: border-box;
        padding: 5px;

    }

    .selectButtons img {
        width: 100%;
        border: 1px solid #828282;
        border-radius: 5px;
    }
    .showSelectPannel {
        transform: translateY(0%);
    }
    @media screen and (max-width: 1024px) {
        .selectButtons {
            width: 100% !important;
        }

        .selectButtons div {
            width: 49% !important;
        }
        .overlay .overlay-warp .overlay-open{
            width: 90% !important;
        }


    }



</style>