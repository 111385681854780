<template>
    <div class="overlay" :class="{'showMainTainingPannel':$store.state.isMaintaining}">
        <div class="overlay-warp">
            <div class="overlay-open">
                <div class="register-warp">
                    <div class="login-head" style="justify-content: center">
                        <h4>
                            <img src="../assets/images/logo/gamen/logo.png" style="height: 50px"/>
                        </h4>
                    </div>
                    <div style="padding: 5px;width: 100%;display: flex;justify-content: center;align-items: center">
                        <div v-html="$store.state.maintainingMessage"> </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../common/mixin";
    import {getSiteStatus, logout, register, saveNotMemberQuestion} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";

    export default {
        name: "MainTainingComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                content: '',
            }
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
                this.$store.state.isShowNotMemberCustomerePanel = false;
                this.content=''
            },


        },
        created() {

        },
        computed: {

        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .register-warp {
            width: 370px !important;
            height: 350px !important;
            background-color: #fff9ff;
        }

        .register-warp .register-cont .register-d .register-left {
            width: 100% !important;
        }

        .register-warp .register-cont .register-d .register-right {
            display: none !important;
        }
    }

    .showMainTainingPannel {
        transform: translateY(0%);
    }

    .register-warp {
        width: 410px;
        height: 350px;
        background-color: var(--loginWarpBg);
        color: #fff9ff;
        border:  1px solid #1f1f1f;
    }

    .register-warp .login-head {
        background-color: #000000;

        color: #fff;
        height:100px;
        line-height: 100px;
        padding: 0 5px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .register-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 40px;
        padding: 2px;
        cursor: pointer;
    }

    .login-panel-close img {
        height: 30px;
    }

    .register-warp .register-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 5001;
        margin-top: -40px;
    }

    .register-warp .register-cont .register-d {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 40px;
    }

    .register-warp .register-cont .register-d .register-left {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
        position: relative;
    }

    .error-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #ff0000;
    }

    .register-warp .register-cont .register-d .register-left .register-logo {
        text-align: center;
        margin-top: 14px;
    }

    .register-warp .register-cont .register-d .register-left .register-logo img {
        max-width: 230px;
    }

    .register-warp .register-cont .register-d .register-left .register-from {
        width: 100%;
        padding: 0 16px 16px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        margin-top: 20px;
        padding: 0 2px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field span {
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        font-size: 13px;
        color: #adadad;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field input {
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #3c3b3b;
        border-radius: 0;
        color: #fff9ff;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: left;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button {
        width: 100%;
        padding: 0 16px 16px;
        margin-top: 5px;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button button {
        width: 100% !important;
    }

    .register-warp .register-cont .register-d .register-left .login {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
    }

    .register-warp .register-cont .register-d .register-left .register {
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
        background-color: var(--regBtnBg);
        color: #fff9ff;
    }

    .register-warp .register-cont .register-d .register-left .tel-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #0b1423;
        line-height: 28px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom {
        width: 100%;
        background-color: #2b2f35;
        position: absolute;
        bottom: 0;
        padding: 16px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom .join-text {
        color: #fff9ff;
        font-size: 12px;
    }

    /*login panel right*/
    .register-warp .register-cont .register-d .register-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
    }

</style>