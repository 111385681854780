import { render, staticRenderFns } from "./LoginComp.vue?vue&type=template&id=33690fca&scoped=true&"
import script from "./LoginComp.vue?vue&type=script&lang=js&"
export * from "./LoginComp.vue?vue&type=script&lang=js&"
import style0 from "./LoginComp.vue?vue&type=style&index=0&id=33690fca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33690fca",
  null
  
)

export default component.exports