<template>
  <div class="message_mask_layer" :class="{'show': showMessage}" v-if="$store.state.isLogin">
    <div class="popup_message" @click="go2message()">
      <div class="message_box">
        <img src="../assets/images/bg/message_bg_01.png">
        <span class="count01">{{mcount}}개</span>
<!--        <span class="count02"><i class="fa fa-envelope-o" aria-hidden="true"></i>쪽지</span>-->
      </div>
    </div>
  </div>
</template>

<script>
  import {getUnCheckedMessageCount} from "../network/userRequest";
  import {mapGetters} from 'vuex'

  export default {
    name: "MessageComp",
    data() {
      return {
        showMessage: false,
      }
    },
    computed: {
      ...mapGetters({
        'mcount': 'getUnCheckedMessageCount',
      }),
    },
    methods: {
      go2message() {
        this.$router.replace('/message')
      }
    },
    created() {
      if(this.$store.state.isLogin){
        this.showMessage = this.$store.state.uncheckedMessageCount > 0;
        if (this.$route.name === 'message') {
          this.showMessage = false;
        }
      }else {
        this.showMessage = false;
      }

    },
    watch: {
      mcount(newVal) {
        if(this.$route.name !== 'message'){
          this.showMessage = this.$store.state.uncheckedMessageCount > 0
        } else {
          this.showMessage = false;
        }
      }
    }
  }
</script>

<style scoped>

  .message_mask_layer {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    display: none;
  }

  .show {
    display: block;
  }


  .popup_message {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 186px;
    margin: -151px 0px 0px -93px;
    z-index: 200;
  }

  @media screen and (max-width: 800px) {
    .popup_message {
      left: 40%;
    }
  }

  .popup_message .message_box {
    position: relative;
    cursor: pointer;
  }

  .popup_message .message_box .count01 {
    position: absolute;
    top: 130px;
    left: 76px !important;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: block;
    line-height: 26px;
    color: #ff0000;
  }

  .popup_message .message_box .count02 {
    position: absolute;
    top: 48px;
    left: 115px;
    font-size: 16px;
    width: 80%;
    font-weight: bold;
    text-align: center;
    display: inline-block;;
    color: #b30024;
    line-height: 26px;
  }
</style>