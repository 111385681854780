<template>
    <div class="banner">
<!--        <div  style="margin-top: 5px">-->
<!--            <a href="http://나이프주소.com" target="_blank"  class="pc_mode">-->
<!--                <img alt="" src="../assets/images/banner/knife/www.gif" style="width: 100%">-->
<!--            </a>-->
<!--        </div>-->

<!--        <div style="margin-top:5px"  class="pc_mode">-->
<!--            <router-link tag="a"  :to="{path: '/customer_center', query: {t: new Date().getTime()}}">-->
<!--                <img alt="" src="../assets/images/banner/knife/kakao.gif" style="width: 100%">-->
<!--            </router-link>-->
<!--        </div>-->



    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner{
        width: 100%;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>